
import { createApp } from 'vue'
// import { createI18n } from 'vue-i18n'
import App from './App.vue'
// 多國語系
// import zh from "./language/zh-TW.json";
// import en from "./language/en-US.json";
// import ja from "./language/ja-JP.json";

// const i18n = createI18n({
//     legacy: false,
//     locale: localStorage.getItem("locale") ?? "zh-TW",
//     fallbackLocale: "zh-TW",
//     globalInjection: true,
//     messages: {
//         "zh-TW": zh,
//         "en-US": en,
//         "ja-JP": ja
//     }
// });
import i18n from './language'
import router from './router'
import store from './store'

import './icons';




import 'bootstrap'; //  bootstrap 
import './validators'; // vee-validation rules
// import mqttVueHook from 'mqtt-vue-hook'

const app = createApp(App);


app.use(router);
app.use(store);
// app.use(mqttVueHook, 'mqtt://192.168.33.90:1883', {
//     clean: false,
//     keepalive: 60,
//     clientId: `mqtt_client_${Math.random().toString(16).substring(2, 10)}`,
//     connectTimeout: 4000,
// })


// 多國語系
app.use(i18n)

import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';

// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
app.component('font-awesome-icon', FontAwesomeIcon) //使用font-awsome
app.component('font-awesome-layers', FontAwesomeLayers)
app.component('font-awesome-layers-text', FontAwesomeLayersText)

app.mount('#app');

<script src="scripts/DragDropTouch.js"></script>
// createApp(App).use(store).use(router).mount('#app')
