<template>
  <hr v-show="listAry.length > 0" />
  <div
    class="border"
    :class="{ 'border-danger': isDanger, 'border-warning': !isDanger }"
    v-show="listAry.length > 0"
  >
    <div
      class="d-flex justify-content-between"
      :class="{
        'bg-danger': isDanger,
        'text-light': isDanger,
        'bg-warning': !isDanger,
        'text-dark': !isDanger,
      }"
    >
      <div class="m-2 blink_me">{{ $t("alert") }} {{ $t("items") }}</div>
      <div class="m-2 blink_me">
        <font-awesome-icon icon="exclamation-triangle" class="" />
      </div>
    </div>
    <div class="">
      <ol class="mt-3">
        <li v-for="(item, index) in listAry" :key="index">
          <div class="d-flex justify-content-between">
            <div>
              {{ $t("alert_title", { err_type: $t(item.description.type) }) }}
            </div>
            <div class="info-group">
              <button
                type="button"
                class="btn px-1 btn-sm"
                :class="{ 'btn-danger': isDanger, 'btn-warning': !isDanger }"
                data-bs-toggle="modal"
                :data-bs-target="'#' + item.modalId"
              >
                {{ $t("dets") }}
              </button>
            </div>
          </div>
        </li>
      </ol>
    </div>
  </div>

  <bs-modal
    v-for="(modal, index) in listAry"
    :key="index"
    :title="$t('alert_title', { err_type: $t(modal.description.type) })"
    :modalid="modal.modalId"
    :content="
      $t(modal.description.type + '-description', {
        room_no: 1,
        bed_no: 3,
        device_no: 'DX002',
      })
    "
  />
  <!-- :content="modal.description.detail" -->
</template>

<script>
import { defineComponent } from "vue";
import BsModal from "@/components/bootstrap/BsModal.vue";

export default defineComponent({
  name: "DeviceAlerts",
  components: { BsModal },
  props: {
    level: {
      type: String,
      default: "alert",
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup(props) {
    // console.log('props.type', props.type)
    let isDanger = false;
    if (props.level === "alert") {
      isDanger = true;
    }

    const listAry = [];
    props.list.forEach((description) => {
      let modalId =
        "modalid" + "-" + +new Date() + Math.floor(Math.random() * 1000);

      listAry.push({ description, modalId });
    });

    // console.log('isDanger', isDanger)
    return { listAry, isDanger };
  },
});
</script>

<style>
</style>