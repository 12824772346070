
export default {
  namespaced: true,
  state: function () {
    const maxBuildingHeight = 20;
    const roomAdd = { skipFour: true, autoNaming: true };
    const bedAdd = { skipFour: true, autoNaming: true };
    const floorAdd = { skipFour: true, autoNaming: true, defaultName: '一般病房' };
    const deviceType = ['smart-toilet'];
    const deviceGenericProperty = {
      id: 'string',
      manufacturer: 'string',
      manufactureDate: 'timestamp',
      expirationDate: 'timestamp',
      status: 'enum',
      location: 'uuid'
    }; // 設備通用屬性

    const tagCategory = [
      { code: 'physical', name: '生理狀況' },
      { code: 'medical_compliance', name: '醫療指示' },
      { code: 'care_guidance', name: '照護指南' },
      { code: 'preference', name: '個人喜好' },
    ]
    const patientTag = {
      physical: ['發燒', '失溫', '臥床'],
      medical_compliance: ['禁食', '管灌', '點滴', '術後恢復'],
      care_guidance: ['防跌', '注意保暖', '約束'],
      preference: ['素食'],
    };
    const language = ['國語', '英文', '台語', '客家話', '原住民語'];

    const reasonCodes = {
      unavailable: [
        { code: 'not-acceptance', name: '尚未驗收' },
        { code: 'not-installed', name: '尚未安裝' },
        { code: 'maintainace', name: '設備維護' },
        { code: 'repair', name: '設備維修' },
        { code: 'malfunction', name: '設備異常' },
        { code: 'retired', name: '設備除疫' },
        { code: 'unknown', name: '狀態不明' },
        { code: 'sterilization', name: '滅菌' },
        { code: 'disinfection', name: '清消' },
        { code: 'cleaning', name: '設備清潔' },
      ],
      retired: [
        { code: 'acceptance-failed', name: '驗收失敗' },
        { code: 'service-life', name: '已逾使用年限' },
        { code: 'not-repairable', name: '無法修復' }
      ],
      occupied: [
        { code: 'in-use', name: '現正使用中' },
        { code: 'reserved', name: '預約保留中' },
      ],
    }
    const statusCodes = [
      { code: '', name: '請選擇', disabled: true },
      { code: 'registered', name: '已註冊' },
      { code: 'assigned', name: '已分派' },
      { code: 'occupied', name: '已被使用' },
      { code: 'available', name: '可使用' },
      { code: 'unavailable', name: '不可使用' },
      { code: 'retired', name: '已除疫' },
    ]

    const alertCodes = {
      'smart-toilet': [
        { code: 'waste-bucket-misplaced', name: '污桶位移' },
        { code: 'waste-bucket-full', name: '污桶已滿' },
        { code: 'water-shortage', name: '水箱缺水' },
        { code: 'hardware-error', name: '空燒警告' },
        { code: 'water-overheated', name: '水溫過高' },
      ]
    }
1.
    const dataTypes = [
      { code: 'Float', name: '浮點數', description: '帶小數點的數字如3.14等' },
      { code: 'Integer', name: '整數', description: '不帶小數點的數字如1,100,1000等' },
      { code: 'Boolean', name: '布林值', description: '真(true)或假(false)' },
      // 這二個很難結構化，先不實作 ... 
      // { code: 'Array', name: '陣列', description: '陣列' }, 
      // { code: 'Object', name: '物件', description: '物件' }, 
      { code: 'String', name: '字串', description: '字串' },
      { code: 'Datetime', name: '日期', description: '可轉成日期的字串，如2022-08-02 12:34:56' },
    ]

    const serverList = [
      { id: '', name: 'gateway', ip: '192.168.6.1', port: 9001, services: ['mqtt'] }
    ]
    return {
      floorAdd, maxBuildingHeight, roomAdd, bedAdd, deviceType, deviceGenericProperty, patientTag, language,
      reasonCodes, statusCodes, tagCategory, dataTypes, alertCodes
    }
  },
  mutations: {
  },
  actions: {
  },
  getters: {
    get: state => property => {
      console.log(property, state[property]);
      return state[property];
    },
    getTags: state => category => {
      console.log('category', category);
      return state.patientTag[category] || []
    },
  }
}