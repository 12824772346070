<template>
  <!-- <div
    class="
      mt-3
      mb-2
      d-flex
      flex-nowrap
      justify-content-between
      sticky-top
      bg-light
    "
    style="z-index: 1039"
  >
    
    <div class="d-block fs-4 px-3">
      <a href="#" @click="goBack"><font-awesome-icon icon="angle-left" /></a>
    </div>
    <div class="d-block fs-4 text-nowrap">
      <img
        class="mb-2"
        src="@/assets/logo.png"
        style="height: 30px"
      />大心生物科技資訊平台
    </div>
    <div class="d-block d-lg-none fs-5 text-end">
      {{ shortTime }}
    </div>
    <div class="d-none d-lg-block text-nowrap fs-5">{{ fullTime }}</div>
  </div> -->
  <div class="bg-light mb-3">
    <div class="row fs-4 text-nowrap">
      <div class="col ms-3 mt-3">
        <a href="#" @click="goBack"><font-awesome-icon icon="angle-left" /></a>
      </div>
      <div class="col-6 text-center mt-3">
        <img class="mb-0" src="@/assets/logo.png" style="height: 30px" />
        {{ t('daxin-title')}}
      </div>
      <div class="col me-2 mt-3">
        <div class="text-end d-block d-lg-none">{{ shortTime }}</div>
        <div class="text-end d-none d-lg-block">{{ fullTime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import dateFormat from "dateformat";
import { useRouter } from "vue-router";

import { ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "HeaderBar",
  setup() {
    const router = useRouter();

    let now = new Date();
    // console.log('state-=----===-', state)
    let fullTime = ref(dateFormat(now, "yyyy-mm-dd HH:MM:ss"));
    let shortTime = ref(dateFormat(now, "HH:MM:ss"));

    onMounted(() => {
      console.log("onMounted @Header");
      window.setInterval(() => {
        now = new Date();
        fullTime.value = dateFormat(now, "yyyy-mm-dd HH:MM:ss");
        shortTime.value = dateFormat(now, "HH:MM:ss");
      }, 1000);
    });

    const goBack = () => {
      // router.back();
      router.push("/");
    };

    const { t } = useI18n();
    return { fullTime, shortTime, goBack, t };
  },
});
</script>

<style scoped>
/* .header {
  position: sticky;
  top: 0px;
  z-index: 1039;
  height: 60px;
} */
</style>