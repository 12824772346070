import { createStore } from 'vuex'
// import device from '@/store/modules/fake'
import device from '@/store/modules/device'
import fake from '@/store/modules/fake'
import ubus from '@/store/modules/ubus'
import auth from '@/store/modules/auth'
import location from '@/store/modules/location'
import config from '@/store/modules/config'
import notification from '@/store/modules/notification'
import administration from '@/store/modules/administration'
// import mqtt from '@/store/modules/mqtt'

export default createStore({
  modules: {
    device,
    ubus,
    auth,
    location,
    config,
    notification,
    administration,
    fake,
    // mqtt,
  }

})
