<template>
  <!-- alertDetail1Modal -->
  <div class="modal fade" :id="modalId" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-danger text-light">{{ props.title }}</div>
        <div class="modal-body" v-html="props.content" />
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{ $t("close") }}
          </button>
          <button type="button" class="btn btn-primary">{{ $t("update") }}</button>
          <!-- @click="$emit('update:tacos', 12)" -->
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap"; // eslint-disable-line
import { defineComponent, onMounted } from "vue";
export default defineComponent({
  props: {
    title: {
      required: true,
      type: String,
    },
    content: {
      type: String,
    },
    level: {
      default: "normal",
      type: String,
    },
    modalid: {
      type: String,
    },
    createInstance: {
      // required: false,
      // type: Object
    },
    // tacos: {
    //   type: Object
    // }
  },
  // emits: ['update:modelValue'],
  // emits: ["update:instance"],

  setup(props, { emit }) {
    let modalInstance;
    onMounted(() => {
      console.log("props;;;;;;;;;;;;;;;;;;;;;;", props);
      // https://penueling.com/%E7%B7%9A%E4%B8%8A%E5%AD%B8%E7%BF%92/vue3-%E7%B4%94bootstrap5-%E6%89%93%E9%96%8Bmodal/
      console.log("props.createInstance", props.createInstance);
      if (props.createInstance === true) {
        modalInstance = new Modal(document.querySelector("#" + modalId), {});
        emit("update:createInstance", modalInstance);
      }
    });
    let isCritical = false;
    let alertIcon = "info-circle";
    if (props.level == "critical") {
      isCritical = true;
      alertIcon = "exclamation-triangle";
    }
    let modalId =
      props.modalid ||
      "modal-" + +new Date() + "-" + Math.floor(Math.random() * 1000);
    emit("onModalIdCreated", () => modalId);
    console.log("emit received modal id:", modalId);
    console.log("=============props.content", props.content);
    return { props, isCritical, alertIcon, modalId, modalInstance };
  },
});
</script>

<style scoped>
</style>