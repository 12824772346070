import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faPhone, faShoppingCart, faExclamationTriangle, faMale, faFemale, faPlus, faMinus,
    faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faInfoCircle, faVenus, faMars, faCat, faFrog, faDove, // eslint-disable-line
    faFish, faDog, faArrowUpFromBracket, faUserPlus, faBars, faCircleCheck, faBan, faPen, faTrashCan,
    faCheck, faXmark, faPenToSquare, faAnglesUp, faAnglesDown, faRotateRight, faMagnifyingGlass, faPlugCirclePlus,
    faFilter, faFilterCircleXmark, faCrown, faArrowsRotate, faFaceFrown, faFaceSmile, faPencil, faWandMagicSparkles,
    faFloppyDisk, faUpload, faDownload, faPenRuler, faLanguage, faSquareXmark, faMicrochip, faCoffee, faSpinner, faCircle,
    faEnvelope, faGripLines, faGripLinesVertical, faCertificate, faFile, faCirclePlus, faBell
} from "@fortawesome/free-solid-svg-icons"
import {
    faCircleCheck as farCircleCheck, faCircle as farCircle
} from "@fortawesome/free-regular-svg-icons"
import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons'
library.add(faPhone, faShoppingCart, faFacebookSquare, faInstagram, faExclamationTriangle,
    faMale, faFemale, faPlus, faMinus,
    faAngleLeft,
    faAngleRight, faInfoCircle, faVenus, faMars,
    faCat, faFrog, faDove, faFish, faDog, faArrowUpFromBracket,
    faUserPlus, faBars, faAnglesLeft, faAnglesRight, faAnglesUp, faAnglesDown,
    faCircleCheck, faBan, faCheck, faXmark, faPenToSquare, faPen, faTrashCan, faRotateRight,
    faMagnifyingGlass, faPlugCirclePlus, faFilter, faFilterCircleXmark, faCrown, faArrowsRotate,
    faFaceFrown, faFaceSmile, faPencil, faWandMagicSparkles, faFloppyDisk, faUpload, faDownload,
    faPenRuler, faLanguage, faSquareXmark, faMicrochip, faCoffee, faSpinner, faCircle, faEnvelope,
    farCircle, farCircleCheck, faGripLines, faGripLinesVertical, faCertificate, faFile, faCirclePlus,
    faBell
)
