<template>
  <div class="menu">
    <nav class="navbar navbar-expand-lg navbar navbar-dark bg-primary">
      <div class="container-fluid justify-content-between">
        <router-link
          v-for="item in list"
          :key="item.route"
          class="navbar-nav text-decoration-none text-light"
          :to="item.route">
          <!-- {{ item.text }} -->
          {{$t(item.translateCode)}}
          </router-link
        >
        <router-link
          v-show="username === ''"
          class="navbar-nav text-decoration-none text-light"
          to="/login"
          >{{$t('login')}}</router-link
        >
        <div
          role="button"
          v-show="username !== ''"
          @click="logout"
          class="text-light text-decoration-none"
        >
          {{$t('logout')}}({{ username }})
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { defineComponent, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";


export default defineComponent({
  name: "MenuBar",
  props: { list: { type: Object, required: true } },
  setup() {
    // const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const username = computed(() => {
      return store.getters["auth/checkLogin"].username;
    });
    console.log("isLogin:", username.value);
    const logout = () => {
      store.dispatch("auth/doLogout");
      router.push("/");
    };
    watch(username, (newVal, oldVal) => {
      let content;
      if (newVal === "") {
        content = `帳號<font color="red">${oldVal}</font>已登出`;
      } else {
        content = `帳號<font color="red">${newVal}</font>已登入`;
      }

      const cfg = { closeAfter: 5, route: "global" };
      const param = { title: "帳號狀態異動", content, cfg };

      console.log("----------------->>>>", param);
      store.commit("notification/addToast", param);
    });


    return { username, logout };
  },
});
</script>

<style>

</style>