<template>
  <div ref="toastNode" class="toast mt-2 mb-2" role="alert" :id="props.id">
    <div class="toast-header bg-primary text-light">
      <!-- <img src="..." class="rounded me-2" alt="..."> -->
      <strong class="me-auto">{{ props.title }}</strong>
      <small v-if="autoClose">{{ showCloseAfter }}</small>
      <small v-else>{{ showTimeElapsed }}</small>
      <button
        v-if="!autoClose"
        type="button"
        class="btn-close btn-close-white"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></button>
    </div>
    <div class="toast-body" v-html="props.content" />
    <!-- <div class="toast-body">
      {{ props.content }}
    </div> -->
  </div>
</template>

<script>
import { onMounted, ref, computed, onBeforeUnmount } from "@vue/runtime-core";
import { useI18n } from "vue-i18n";
import { Toast } from "bootstrap";

export default {
  props: {
    title: {
      required: false,
      type: String,
    },
    description: {
      required: false,
      type: String,
    },
    content: {
      required: false,
      type: String,
    },
    cfg: {
      default: () => {
        return { closeAfter: 10, action: "dispose", timestamp: undefined };
      },
      type: Object,
    },
    id: {
      default: () => {
        return (
          "toast-" + +new Date() + String(Math.round(Math.random() * 1000))
        );
      },
      type: String,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const { t } = useI18n();
    let countDown = ref(props.cfg.closeAfter);
    let autoClose = countDown.value > 0;
    let timeElapsed = ref(0);

    if (props.cfg.timestamp !== undefined) {
      // console.log('----->>>>', + new Date(), props.cfg.tclimestamp)
      timeElapsed.value = Math.floor((new Date() - props.cfg.timestamp) / 1000);
      // console.log('timeElapsed.value', timeElapsed.value)
    }

    let toast;
    // console.log('----------------setup()', autoClose)
    const toastNode = ref(null);
    const showCloseAfter = computed(() => {
      // return countDown.value + " 秒後關閉...";
      return t("close-after-second", { time: countDown.value });
    });
    const showTimeElapsed = computed(() => {
      let displayText;
      if (timeElapsed.value < 60) {
        // displayText = timeElapsed.value + " 秒鐘以前...";
        displayText = t("second-ago", { time: timeElapsed.value });
      } else if (timeElapsed.value < 3600) {
        // displayText = Math.floor(timeElapsed.value / 60) + " 分鐘以前...";
        displayText = t("hour-ago", {
          time: Math.floor(timeElapsed.value / 60),
        });
      } else {
        // displayText = Math.floor(timeElapsed.value / 3600) + " 小時以前...";
        displayText = t("hour-ago", {
          time: Math.floor(timeElapsed.value / 3600),
        });
      }
      return displayText;
    });
    let timer;
    onMounted(() => {
      // console.log("onMounted @ BSToast", props.id);
      console.log("onMounted (props):", props);
      toast = new Toast(toastNode.value, { autohide: false });
      toast.show();
      //   console.log(timer, typeof timer);
      // 每個 Toast 都會有一個自已的 Timer，其作用為計算自動關閉時間及顯示事件時間
      timer = setInterval(() => {
        // console.log('interval running @', props.id , timeElapsed.value, countDown.value)
        timeElapsed.value++;
        if (countDown.value > 0) {
          countDown.value--;
        } else {
          if (autoClose === true) {
            if (props.cfg.action === "hide") {
              // console.log("hide toast id:", props.id);
              toast.hide();
              // 這邊似乎可以做成 若干時間後再提醒
            } else {
              // console.log("dispose toast id:", props.id);
              toast.dispose();
              emit("close", props.id);
            }
          }
          if (toastNode.value.classList.contains("show") === false && timer) {
            clearInterval(timer);
            timer = undefined;
          }
        }
      }, 1000);

      onBeforeUnmount(() => {
        // 在unmount 之前需要把 timeImterval 清掉 不然會報錯...
        // console.log('onBeforeUnmount()');
        // console.log('timer:', timer);
        if (timer) {
          clearInterval(timer);
        }
      });

      // // 取得元件 ...
      // Array.from(document.querySelectorAll("#" + id)).forEach((toastNode) => {
      //     console.log("------------", id, toastNode);
      //     let toast = new Toast(toastNode, option);
      //     console.log('AA', toast)
      //     toast.show()
      // });
      // var myToastEl = document.getElementById(id)
      // var myToast = Toast.getInstance(myToastEl) // Returns a Bootstrap toast instance
      // console.log("AAA", myToastEl, myToast)
    });

    return {
      props,
      toastNode,
      toast,
      countDown,
      showCloseAfter,
      showTimeElapsed,
      autoClose,
    };
  },
  mounted() {
    // pass bootstrap popover options from props
    // Array.from(document.querySelectorAll(".toast")).forEach(
    //   (toastNode) => new Toast(toastNode)
    // );
    // // new Popover(ele,options)
    // Array.from(document.querySelectorAll('button'))
    // .forEach(popoverNode => new Popover(popoverNode, options))
  },
};
</script>

<style scoped>
.toast {
  opacity: 1 !important;
}
</style>