import store from '@/store';
import { defineRule } from "vee-validate";
import { required, min, max, email, between, one_of } from "@vee-validate/rules"; // eslint-disable-line

defineRule('name', value => {
    // console.log('name', min(value, { length: 2 }));

    if (min(value, { length: 2 }) && max(value, { length: 10 })) {
        return true
    }
    return "姓名長度必須介於2-10個字元之間";
})
defineRule('lengthRange', (value, [minLength, maxLength]) => {
    // console.log('lengthRange', value, minLength, maxLength);

    if (min(value, { length: minLength }) && max(value, { length: maxLength })) {
        return true
    }
    return `必須介於${minLength}-${maxLength}個字元`;
})

defineRule('password', (value, [minLength, maxLength]) => {
    // console.log('name', min(value, { length: 2 }));

    if (min(value, { length: minLength }) && max(value, { length: maxLength })) {
        return true
    }
    return `密碼長度必須介於${minLength}-${maxLength}碼之間`;
})
// uc : upper case [a-z], lc: lower case [A-Z], ds: dash , ul: underscore sp: space
// sc: semicolon (;) , fs: forward slash (/) , bs: backslash (\)
// cl: colon (:), no: number 0-9 cm: comma (,), qm: question mark
// em: exclamation mark

const regexMapping = {
    uc: { rule: 'A-Z', name: '大寫字母' }, // Upper Case
    lc: { rule: 'a-z', name: '小寫字母' }, // Lower Case
    ds: { rule: '\\-', name: '小寫字母' }, // DaSh
    ul: { rule: '_', name: '下底線(_)' }, // UnderLine
    sp: { rule: ' ', name: '空格' }, // SPace
    sc: { rule: ';', name: '分號(;)' }, // SemiColon
    fs: { rule: '\\/', name: '斜線(/)' }, // Forward Slash
    bs: { rule: '////', name: '反斜線(\\)' }, // BackSlash
    cl: { rule: ':', name: '冒號(:)' }, // CoLon
    no: { rule: '0-9', name: '數字' }, // Number
    cm: { rule: ',', name: '逗號(,)' }, // CoMma
    qm: { rule: '?', name: '問號(?)' }, // Question Mark
    em: { rule: '!', name: '驚嘆號(!)' }, // Exclamation Mark
}

const getRegexPattern = (input) => {
    input = input.replaceAll('/', '');
    // console.log(input);
    let rule = '';
    let names = []
    for (let i = 0; i < input.length; i += 2) {
        if (regexMapping[input.substring(i, i + 2)] !== undefined) {
            rule += regexMapping[input.substring(i, i + 2)].rule;
            names.push(regexMapping[input.substring(i, i + 2)].name);
        }
        // console.log(input.substring(i, i + 2))
    }

    return { rule: new RegExp('^[' + rule + ']+$'), names }
}

defineRule('constrain', (value, [pattern]) => {
    const { rule, names } = getRegexPattern(pattern);

    if (rule.test(value) === true) {
        return true
    } else {
        return '此欄位只接受:' + names.join(',');
    }
})

defineRule('inetger', value => {
    return typeof value === 'number' && Number.isInteger(value) || '此欄位只接受整數';
})

defineRule('required', value => {
    if (!required(value)) {
        return '此欄位為必填欄位！';
    }
    return true
});

defineRule('phoneNumber', value => {
    let validateResult = false;
    // 如果去除 - 後為 10 碼 且前二碼為 09
    if (value.replace('/-/', '').length === 10 && value.substring(0, 2) == '09') {
        validateResult = (/^09\d{8}$/.test(value) || /^09\d{2}-\d{3}-\d{3}$/.test(value))
    }
    return validateResult || '電話號碼格式不正確';
})
defineRule('cellphone', value => {
    let validateResult = false;
    // 如果去除 - 後為 10 碼 且前二碼為 09
    if (value.replace('/-/', '').length === 10 && value.substring(0, 2) == '09') {
        validateResult = (/^09\d{8}$/.test(value) || /^09\d{2}-\d{3}-\d{3}$/.test(value))
    }
    return validateResult || '電話號碼格式不正確';
})

defineRule('date', value => {
    // console.log('date validation:', typeof value, value);
    return value instanceof Date || '必須為正確的日期格式'
})
defineRule('email', email);
defineRule('between', between);

defineRule('confirm_field', (value, [target], ctx) => {
    console.log('target:', target, ctx);
    if (required(value) && value === ctx.form[target]) {
        return true;
    }
    return `此欄位必須與${target}欄位資料相符`;
})

defineRule('credit_card', (value) => {
    console.log('check if a valid credit card number', value)
    if (
        // mastercard
        /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/.test(value) ||
        // ae
        /^3[47][0-9]{13}$/.test(value) ||
        // visa
        /^4[0-9]{12}(?:[0-9]{3})?$/.test(value) ||
        // jcb
        /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/.test(value)
    ) {
        return true
    } else {
        return '請輸入符合正確的信用卡卡號';
    }
    // return true;
})

defineRule('status_reason', (value) => {
    let tmp = store.device.state.reasonCode;
    console.log({ tmp });
    let list = 'not-acceptance,not-installed,maintainace,repair,malfunction,retired,unknown'
    return list.split(',').contains(value) || '不正確的原因代碼';
})

defineRule('gender', value => one_of(value, ["male", "female", "男", "女"]) || "請輸入正確的性別資料");
// ctx 為主要的資料物件
// field: 目前欄位的名稱
// form: 整個表單的數值 === handleSubmit 裡面的 values ...
// target: 驗證規則的數值 
defineRule('requiredWhen', (value, [target], ctx) => {
    // console.log('requiredWhen', { value, target, ctx });
    // rooms[0].reason
    let regexPattern = /^(?<form>.*?)\[(?<idx>\d+)\]\.(?<field>.*)$/
    const matches = ctx.field.match(regexPattern);
    let nestedAry = false;
    if (matches?.groups) {
        nestedAry = true;
        // console.log(matches.groups)
    }
    // console.log({ matches, nestedAry });

    let [fld, vals] = target.split('=');
    // fle = 欄位名稱 vals = 若等於的值
    let isRequired = vals.split('&').some(val => {
        // console.log('=====', ctx.form[fld], val);
        if (nestedAry) {
            // useFieldArray() 下的 form 格式是
            // ctx.field 會在名稱前面自動帶上 rooms[0] => rooms[0].reason
            // ctx.form = {rooms: [{...},{...}]} 因此要用 ctx下的field去判斷要比對的值...
            let { groups }  = matches;
            return ctx.form[groups.form][groups.idx][fld] === val
        } else {
            return ctx.form[fld] === val
        }
    })

    if (isRequired === true && !required(value)) {
        // return `當${fld}值為` + vals.split('&').join('或') + '此欄位為必填'
        return '此欄位為必填欄位。'
    }

    return true
})

const fakeApi = () => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(true);
        }, 1000);
    })
}
defineRule('deviceCheck', async (value) => {
    if (/\d{3}/.test(value) === false) {
        return '設備名稱必須為3位數字'
    } else {
        if (await fakeApi() === true) {
            let device = await store.getters['device/filterdList']({ deviceName: 'DX-' + value })
            console.log('device:', device);
            if (device.length > 0) {
                return `設備名稱(DX-${value})已存在`
            }
        }
    }
    console.log('deviceDuplicateCheck', value)
    return true;
})

defineRule('chinese', value => {

    if (/^[\u4e00-\u9fa5]+$/.test(value)) {
        return true
    }
    return '此欄位只能使用中文'
})

defineRule('code', (value, param, ctx) => {

    if (/^[a-z_0-9-]+$/.test(value)) {
        return true
    }
    return '欄位限用小寫英文、數字、底線或中線';
})

defineRule('maxDimensions', (files, [width = 0, height = 0]) => {
    console.log('maxDimensions is called', files, files?.length, Array.isArray(files));
    if (files === undefined || files.length === 0) {
        console.log('return true')
        return true;
    }
    const validateImage = (file, width, height) => {
        const URL = window.URL || window.webkitURL;
        return new Promise(resolve => {
            const image = new Image();
            image.onerror = () => resolve({ valid: false });
            image.onload = () => {
                let result = true;
                if (width !== undefined) {
                    result = image.width <= Number(width)
                }
                if (height !== undefined) {
                    result = image.height <= Number(height)
                }
                resolve(result);
            }
            image.src = URL.createObjectURL(file);
        });
    };
    const errors = []
    const list = [];
    for (let i = 0; i < files.length; i++) {
        // if file is not an image, reject.
        if (! /\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(files[i].name)) {
            return '此欄位只能上傳圖片檔';
        }
        list.push(files[i]);
    }
    return Promise.all(list.map(file => validateImage(file, width, height))).then(values => {
        return values.every(v => v) || `圖片檔案尺寸不可大於 (${width} x ${height})`;
    })
    // console.log('maxDimensions result is', validateResult);
    // return validateResult;

})