<template>
  <div
    class="alert fade mt-3"
    :class="{
      'alert-danger': isCritical,
      'alert-primary': !isCritical,
      'alert-dismissible': isDismissible,
      show: isShow,
    }"
    role="alert"
  >
    <h4 class="alert-heading">
      <font-awesome-icon :icon="alertIcon" />
      {{ props.title }}
    </h4>
    <hr />
    {{ props.message }}
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
      @click="$emit('closeAlert', props.id)"
    ></button>
  </div>
</template>

<script>
// 'alert-dismissible' : isDismissible,
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    id: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    message: {
      required: true,
      type: String,
    },
    level: {
      default: "normal",
      type: String,
    },
    show: {
      default: true,
    },
    dismissible: {
      default: true,
    },
  },
  emits: ["closeAlert"],
  setup(props) {
    let isCritical = false;
    let alertIcon = "info-circle";
    if (props.level == "critical") {
      isCritical = true;
      alertIcon = "exclamation-triangle";
    }
    let isShow = props.show === true;
    let isDismissible = props.dismissible === true;

    return { props, isShow, isCritical, isDismissible, alertIcon };
  },
});
</script>

<style scoped>
</style>