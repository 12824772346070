import { integer } from "@vee-validate/rules";
import dateFormat from "dateformat";
import { v4 as uuid } from "uuid";


export default {
  namespaced: true,
  state() {

    const manufacturerList = [
      { name: '大心生物科技', code: 'daxin' }
    ]

    const deviceTypeList = [
      { code: 'smart-toilet', name: '智慧排遺設備', manufacturers: ['daxin'] },
      { code: 'urine-bag', name: '智慧尿袋設備', manufacturers: ['daxin'] }
    ]

    const deviceIdentity = [
      {
        id: '9eea9f2d-dd70-4010-9e14-43176e5d8bdf',
        manufacturer: 'daxin',
        type: 'smart-toilet',
      },
      {
        id: 'a9b156cf-c24d-4a15-9777-47547ac92559',
        manufacturer: 'daxin',
        type: 'urine-bag',
      },
    ]
    // 定義：設備有的屬性，即用來描述設備的欄位
    const deviceProperties = [
      {
        id: '6839faa5-badb-407a-8b46-a95fd35cf011',
        genericId: '9eea9f2d-dd70-4010-9e14-43176e5d8bdf',
        properties: [
          { id: '1c74964d-ce61-4dc3-8374-087614784122', code: 'current_water_temperature', name: '目前水溫', type: 'Float', unit: '', sort: 1 },
          { id: '2bf82bdb-c0fe-4dfb-be8b-81590266df4f', code: 'target_water_temperature', name: '設定水溫', type: 'Float', unit: '', sort: 2 },
          { id: '931fd93a-aabe-4195-9ca0-d7bf17d1434b', code: 'stool_count', name: '大便次數', type: 'Integer', unit: '', sort: 3 },
          { id: 'b6c7fe20-f93b-4699-9f71-52993ef09447', code: 'urine_count', name: '小便次數', type: 'Integer', unit: '', sort: 4 },
          { id: '0f8598e1-1d3c-457f-85b7-534a70da90d0', code: 'power_on', name: '設備開機', type: 'Boolean', unit: '', sort: 5 },
          { id: 'e7ab1893-5762-4222-b9ab-0a4a8bbab7c9', code: 'stand_by', name: '設備待機', type: 'Boolean', unit: '', sort: 6 },
        ]
      }
    ]

    

    // 事件 ... 用於記錄發生了什麼事？ 例如：
    // information: 設備開、關機
    // vital-signs: 大便發生、小便發生
    // warning: 不影響使用的事件，如水溫偏低 (但仍可正常運作)
    // alert: 告警，導致設備無法使用的事件 
    // 通用屬性
    // 發生時間
    // 結束時間
    // 
    const deviceEvents = [
      {
        id: '7171b969-2e81-4361-82a0-422aa444b3b6',
        genericId: '9eea9f2d-dd70-4010-9e14-43176e5d8bdf',
        eventDefinition: {
          alerts: [ // start_t , end_t , acknology_t
            { id: 'a0af4e20-252d-44ab-92f8-085bb4853df0', code: 'waste-bucket-misplaced', name: '污桶位移', description: '污桶未正確定位，請確認是否放置良好，將污桶抽出後重新安置，若持續告警請連絡技術人員進行障礙排除', sort: 1 },
            { id: '59cce536-0867-46e8-af1f-c6f5b01338a6', code: 'waste-bucket-full', name: '污桶已滿', description: '', sort: 2 },
            { id: 'ac63ee3e-95e8-43db-9846-f43e056d69e2', code: 'water-shortage', name: '水箱缺水', description: '', sort: 3 },
            { id: '6dfef476-12ed-466a-88e6-61e0f33d9ed3', code: 'hardware-error', name: '空燒警告', description: '', sort: 4 },
            { id: 'a80137ff-9c1a-4700-99af-96a5692d80cc', code: 'water-overheated', name: '水溫過高', description: '', sort: 5 },
          ],
          operation: [
            { id: '75f90a17-2783-49eb-83e2-d28018d7baa0', code: 'stool-procedure', name: '大便行程', measurements: ['stool_weight'], count: 'stool_count' },
            { id: '4539b91c-aa1d-4a8a-8640-4240c7a66db9', code: 'stool-procedure', name: '小便行程', measurements: ['urine_volume'], count: 'urine_count' },
          ]
        }
      }
    ]

    const apis = [
      {
        id: '1ea5033d-93c7-4216-b95f-90492c7deaa0',
        genericId: '9eea9f2d-dd70-4010-9e14-43176e5d8bdf',
        apis: [
          { id: '5bd6b309-71cd-454d-b415-a5190bb2b88f', }
        ]
      }
    ]

    const deviceMeasurement = [
      { id: '5ba94b30-ba02-4397-9774-126c1b917bf7', code: 'stool_weight', name: '大便重量', measure: 'mass', unit: ['kg', 'g', 'mg'] },
      { id: '7ad70f30-c7d0-4bb6-8853-d0f11c95f95a', code: 'urine_volume', name: '尿量', measure: 'volume', unit: ['l', 'ml'] },
    ]

    // const measurementConversion = {
    //   mass: {
    //     metric:[
    //       { code: 'g', name: 'gram', translation: '公克' , baseUnit: 'g', conversion: 1},
    //       { code: 'kg', name: 'kilogram', translation: '公斤', baseUnit: 'g', conversion: 1000},
    //       { code: 'mg', name: 'milligram', translation: '毫克', baseUnit: 'g', conversion: 0.001},
    //     ],
    //     imperial: [
    //       { code: 'oz', name: 'ounce', translation: '盎司', baseUnit: 'oz', conversion: 1},
    //       { code: 'lb', name: 'pound', translation: '磅', baseUnit: 'oz', conversion: 16}
    //     ]
    //   },
    //   volume: {
    //     metric: [
    //       { code: 'l', name: 'litre', translation: '公升' , baseUnit: 'l', conversion: 1},
    //       { code: 'ml', name: 'millilitre', translation: '毫升' , baseUnit: 'l', conversion: 1},
    //     ],
    //     imperial: [
    //       { code: 'gal', name: 'ounce', translation: '加侖', baseUnit: 'oz', conversion: 1},
    //       { code: 'qt', name: 'quart', translation: '夸脫', baseUnit: 'oz', conversion: 16}
    //       { code: 'pt', name: 'pint', translation: '品脫', baseUnit: 'oz', conversion: 16}
    //     ]
    //   }
    // }
    // const reasonCodes = {
    //   unavailable: [
    //     { code: 'not-acceptance', name: '尚未驗收' },
    //     { code: 'not-installed', name: '尚未安裝' },
    //     { code: 'maintainace', name: '設備維護' },
    //     { code: 'repair', name: '設備維修' },
    //     { code: 'malfunction', name: '設備異常' },
    //     { code: 'retired', name: '設備除疫' },
    //     { code: 'unknown', name: '狀態不明' },
    //     { code: 'sterilization', name: '滅菌' },
    //     { code: 'disinfection', name: '清消' },
    //     { code: 'cleaning', name: '設備清潔' },
    //   ],
    //   retired: [
    //     { code: 'acceptance-failed', name: '驗收失敗' },
    //     { code: 'service-life', name: '已逾使用年限' },
    //     { code: 'not-repairable', name: '無法修復' }
    //   ],
    //   occupied: [
    //     { code: 'in-use', name: '現正使用中' },
    //     { code: 'reserved', name: '預約保留中' },
    //   ],
    // }
    // const statusCodes = [
    //   { code: '', name: '請選擇', disabled: true },
    //   { code: 'registered', name: '已註冊' },
    //   { code: 'assigned', name: '已分派' },
    //   { code: 'available', name: '可使用' },
    //   { code: 'unavailable', name: '不可使用' },
    //   { code: 'retired', name: '已除疫' },
    // ]


    const alertCodes = {
      'smart-toilet': [
        { code: 'waste-bucket-misplaced', name: '污桶位移', description: '' },
        { code: 'waste-bucket-full', name: '污桶已滿', description: '' },
        { code: 'water-shortage', name: '水箱缺水', description: '' },
        { code: 'hardware-error', name: '空燒警告', description: '' },
        { code: 'water-overheated', name: '水溫過高', description: '' },
      ]
    }
    const list = [
      {
        deviceId: "cc5c7fb9-9d39-467a-8ab9-726d3bac078b",
        deviceName: "DX-001",
        type: "smart-toilet",
        patient: 'b4783286-d9f2-4350-82c2-75a980bce46c',
        owner: '',
        location: '',
        serialNumber: '1234567',
        assetNumber: 'abcdefg',
        manufacturer: 'daxin',
        deviceStatus: 'assigned', // registered: 已登記 available: 可使用 unavailable: 不可使用
        reasonCode: '', // 例如： maintainace: 維護 RMA: 送修 malfunction: 故障 retired: 退疫
        // 自定義項目
        expirationDate: '', // 自動填入
        registeredDate: new Date(Date.parse('2022-01-12')), // 啟用時間, //登記時間
        activeDate: new Date(Date.parse('2022-01-12')), // 啟用時間
        lastStatusModifyDate: new Date(Date.parse('2022-07-11 12:34:56')), // 啟用時間
        alertList: ['water-shortage', 'waste-bucket-misplaced'], //
      },
      {
        deviceId: "979c50e6-bba5-4205-9c73-e576309e9863",
        deviceName: "DX-002",
        type: "smart-toilet",
        patient: '',
        owner: '',
        location: '',
        serialNumber: '',
        assetNumber: '',
        manufacturer: 'daxin',
        expirationDate: '', // 自動填入
        deviceStatus: 'unavailable', // registered: 已登記 available: 可使用 unavailable: 不可使用
        reasonCode: 'not-acceptance', // 例如： maintainace: 維護 RMA: 送修 malfunction: 故障 retired: 退疫
        // 自定義項目
        registeredDate: new Date(Date.parse('2022-01-13')), //登記時間 自動填入
        activeDate: new Date(Date.parse('2022-01-13')), // 啟用時間
        lastStatusModifyDate: undefined,
        alertList: [],

      },
      {
        deviceId: "b1743347-7b08-462b-a837-a2ba6c4c56ff",
        deviceName: "DX-003",
        type: "smart-toilet",
        patient: '',
        owner: '',
        location: '',
        serialNumber: '',
        assetNumber: '',
        manufacturer: 'daxin',
        expirationDate: '', // 自動填入
        deviceStatus: 'available', // registered: 已登記 available: 可使用 unavailable: 不可使用
        reasonCode: '', // 例如： maintainace: 維護 RMA: 送修 malfunction: 故障 retired: 退疫
        // 自定義項目
        registeredDate: new Date(Date.parse('2022-01-13')), //登記時間
        activeDate: new Date(Date.parse('2022-01-15')), // 啟用時間
        lastStatusModifyDate: undefined,
        alertList: []
      },
    ];

    return {
      list,
      // reasonCodes, statusCodes
      alertCodes,
      deviceTypeList,
      manufacturerList,
      deviceIdentity,
      deviceProperties,
      deviceEvents,
      deviceMeasurement
    }
  },
  mutations: { // $store.commit('increment',1) // 之後要把新增/修改項目至 state 放這裡 ... 
    setTargetRoom(state, roomId) { // eslint-disable-line
      // console.log('setTargetRoom----------', roomId, this)
      state.targetRoom = parseInt(roomId)
    },
    modifyProperty(state, { genericId, properties }) {
      // 0. 處理排序...

      console.log('properties 222', properties);
      properties = Array.from(properties).map((item, idx) => {
        console.log('item:', item);
        item.sort = idx + 1;
        return item
      })
      // 1. 查詢是否已有存在
      let target = state.deviceProperties.find(item => item.genericId === genericId);
      if (target) {
        target.properties = properties;
      } else {
        state.deviceProperties.push({ id: uuid(), genericId, properties })
      }
    },
    modifyAlert(state, { genericId, alerts }) {
      console.log('alerts 333', alerts);
      alerts = Array.from(alerts).map((item, idx) => {
        
        item.sort = idx + 1;
        return item
      })
      // 1. 查詢是否已有存在
      let target = state.deviceEvents.find(item => item.genericId === genericId);
      if (target) {
        target.eventDefinition.alerts = alerts;
      } else {
        state.deviceProperties.push({ id: uuid(), genericId, eventDefinition: { alerts } })
      }
    }

  },
  actions: { // asynchronized request $store.dispatch('addRandomNumber')
    // 之後會把資料放到db撈取，目前先不處理
    async addRandomNumber(context) { // context is everything inside our store
      // console.log('addRandomNumber Start')
      // console.log('context', context)
      const url = 'https://www.random.org/integers/?num=1&min=1&max=100&col=1&base=10&format=plain&rnd=new'
      const response = await fetch(url)
      // console.log(response.ok, response.status)
      const number = await response.text()
      // console.log({ number })
      // context.commit('increment', parseInt(number))
    },
    loadDataFromDb(interval) {
      interval = parseInt(interval) || 1 // simulator loading time
      interval *= 1000
      // console.log("wait for " + interval + " milliseconds @ Main.vue");
      return new Promise((resolve) => {
        setTimeout(function () {
          resolve('data finish loading');
        }, interval);
      });
    },
    addNewManufacturer: (context, data) => {
      // console.log('addNewManufacturer data:', data)
      context.state.manufacturerList.push(data);
      return true;
    },
    addNewDeviceType: (context, { code, name, manufacturer }) => {
      // const { code, name, manufacturer } = data;
      // let target = []
      const target = context.state.deviceTypeList.filter(item => item.code === code && item.name === name);
      // console.log('target:', target);
      if (target.length === 0) {
        context.state.deviceTypeList.push({
          code, name,
          manufacturers: [manufacturer],
        })
      } else {
        if (target[0].manufacturers.includes(manufacturer) === false) {
          target[0].manufacturers.push(manufacturer);
        }
      }
      // 2022-08-10 新增檢查若 deviceIdentity 沒有的話就新增一筆...
      if (!context.state.deviceIdentity.some(item => (item.type === code && item.manufacturer === manufacturer))) {
        context.state.deviceIdentity.push({
          id: uuid(),
          type: code,
          manufacturer
        })
      }

      return true;
    },
    addDevice: (ctx, data) => {
      // 前端驗證已完成，後端驗證之後再做...
      data.deviceName = 'DX-' + data.deviceName;
      data.alertList = [];
      data.registeredDate = new Date();
      ctx.state.list.push(data)
      return true
    },

    modifyDevice: (ctx, data) => {
      console.log('modifyDevice:', data);
      return new Promise(resolve => {
        let target = ctx.state.list.find(device => device.deviceId === data.deviceId);
        if (target) {
          console.log({ target });
          for (let property in target) {
            console.log(property);
            if (data[property] !== undefined && target[property] !== data[property]) {
              target[property] = data[property]
            }
          }
        }
        resolve(true);
      })
    },

    assignDevice: (ctx, data) => {
      console.log(data);
      const device = ctx.state.list.find(device => device.deviceId === data.deviceId);

      if (device && device.deviceStatus === 'available') {
        device.patient = data.patientId;
        device.deviceStatus = 'assigned';
        device.reasonCode = '';
        device.lastStatusModifyDate = new Date();
        return true;
      }
      return false;
    },

    retainDevice: (ctx, data) => {
      console.log('retainDevice', data);
      const device = ctx.state.list.find(device => device.deviceId === data.deviceId);
      console.log(device);
      if (device && device.patient === data.patientId) {
        device.patient = '';
        device.deviceStatus = 'available';
        device.reasonCode = '';
        device.lastStatusModifyDate = new Date();
        return true;
      }

      return false;
    },
    modifyDeviceMeasurement: (ctx, data) => {
      return new Promise((resolve, reject) => {
        // 找到目標
        let target = ctx.state.deviceMeasurement.find(item => item.code === data.code)
        if (!target) {
          reject(`目標${data.code}不存在`);
        }

        target.type = data.type;
        target.unit = data.unit;

        resolve(true)
      })
    },
    addDeviceMeasurement: (ctx, data) => {
      return new Promise((resolve, reject) => {
        if (!data.id) {
          data.id = uuid();
        }
        console.log(data);
        // check if duplicate...
        const duplicateCheck = {};
        if (ctx.state.deviceMeasurement.find(item => item.name === data.name)) {
          duplicateCheck.name = `${data.name}已存在`;
        }
        if (ctx.state.deviceMeasurement.find(item => item.code === data.code)) {
          duplicateCheck.code = `${data.code}已存在`;
        }

        console.log('duplicateCheck:', duplicateCheck);

        if (Object.keys(duplicateCheck).length > 0) {
          reject(duplicateCheck);
        }

        ctx.state.deviceMeasurement.push(data);

        if (ctx.state.deviceMeasurement.find(item => item.id === data.id)) {
          resolve('true')
        } else {
          resolve('false')
        }
        // resolve(true);
      })
    },
    modifyProperty: (ctx, [genericId, properties]) => {
      //
      return new Promise((resolve, reject) => {
        console.log({ genericId, properties })
        ctx.commit('modifyProperty', { genericId, properties })
        if (ctx.state.deviceProperties.some(item => item.genericId === genericId)) {
          resolve(true);
        } else {
          reject('false');
        }
      })
    },
    modifyAlert: (ctx, [genericId, alerts]) => {
      //
      return new Promise((resolve, reject) => {
        console.log({ genericId, alerts })
        ctx.commit('modifyAlert', { genericId, alerts })
        if (ctx.state.deviceProperties.some(item => item.genericId === genericId)) {
          resolve(true);
        } else {
          reject('false');
        }
      })
    },
  },
  getters: {
    // roomEixsts(state) {
    //   return state.targetRoom * 2
    // },
    peopertyMapping: (state, getters, rootState, rootGetters) => id => {
      // console.log('peopertyMapping is called', id);
      let result = {
        ...state.list.find(device => {
          // console.log(device)
          return device.deviceId === id
        })
      }
      // console.log('peopertyMapping device:', result)
      // mapping property
      if (result.deviceStatus && rootState.config.reasonCodes[result.deviceStatus] !== undefined) {
        result.reasonCodeName = rootState.config.reasonCodes[result.deviceStatus].find(list => list.code === result.reasonCode)?.name || '無';
      } else {
        result.reasonCodeName = '無'
      }
      // alertList property
      // console.log('----------------------');
      // console.log(result.alertList, state.alertCodes, result.type, state.alertCodes[result.type]);
      if (result.alertList && state.alertCodes[result.type] !== undefined) {
        result.alertInfo = result.alertList.reduce((acc, alert) => {
          let name = state.alertCodes[result.type].find(item => item.code === alert).name
          // console.log('name:', name);

          if (name) { acc.push(name) }
          return acc
        }, [])
      } else {
        result.alertInfo = [];
      }
      // console.log('rootState:', rootState.administration)
      if (result.patient !== '') {
        let patient = rootState.administration.patients.find(patient => patient.id === result.patient)
        if (patient) {
          result.patientName = patient.familyName + patient.givenName;
        } else {
          result.patientName = '不明';
        }
      } else {
        result.patientName = '無人使用中';
      }

      result.deviceStatusName = rootState.config.statusCodes.find(list => list.code === result.deviceStatus)?.name || '無';
      result.typeName = state.deviceTypeList.find(list => list.code === result.type)?.name || '無';
      result.manufacturerName = state.manufacturerList.find(list => list.code === result.manufacturer)?.name || '無';
      result.activeDateString = dateFormat(result.activeDate, 'yyyy-mm-dd');
      result.registeredDateString = dateFormat(result.registeredDate, 'yyyy-mm-dd');
      return result;
    },
    filterdList: (state, getters) => (filter, option = {}) => {
      let resultAry = [];
      console.log('filter6667:', filter)
      if (filter === undefined) {
        // console.log('filterdList', state.list);
        resultAry = state.list
      } else {
        // return state.list.filter(device => {
        //   return Object.keys(filter).some(property => {
        //     // console.log('xxx', property, device[property], filter[property])
        //     return device[property] === filter[property]
        //   })
        // })
        resultAry = state.list.reduce((filtered, device) => {
          let isMatched = Object.keys(filter).every(property => {
            // console.log('xxx', property, device[property], filter[property])
            if (property === 'deviceName') { // 設備名稱用模糊比對
              return filter[property] === '' || device[property].includes(filter[property]);
            } else { // 其他用精確比對
              return filter[property] === '' || device[property] === filter[property]
            }
          })
          if (isMatched) {
            // console.log(device)
            filtered.push(device);
          }
          return filtered
        }, [])
      }

      let tmp = resultAry.map(device => getters.peopertyMapping(device.deviceId))
      console.log('filterdList::::', tmp)
      // debugger;
      return tmp
    },

    queryDevice: (state) => filter => {
      console.log(filter); // 目前回傳所有設備
      return state.list;
    },

    getReasonCodes: (state, getters, rootState) => code => {
      return rootState.config.reasonCodes[code]
    },
    getStatusCodes: (state, getters, rootState) => rootState.config.statusCodes,
    getManufactureList: state => state.manufacturerList,
    getDeviceTypeList: state => manufacturer => {
      console.log('getDeviceTypeList');
      if (manufacturer === undefined) {
        return state.deviceTypeList
      } else {
        return state.deviceTypeList.reduce((list, deviceType) => {
          console.log(deviceType);
          if (deviceType.manufacturers.includes(manufacturer)) {
            // console.log('delete manufacturers..........');
            // delete (deviceType.manufacturers);
            list.push(deviceType);
          }
          return list
        }, [])
      }
    },
    getDeviceNextNo: state => {
      let tmp = [...state.list]
      tmp.sort(function (a, b) {
        // console.log(a, b);
        return b.deviceName.localeCompare(a.deviceName)
      })
      if (tmp.length === 0) {
        return '001';
      } else {
        let max = parseInt(tmp[0].deviceName.replace('DX-', ''));
        max++;
        return ('0000' + max).slice(-3)
      }
    },
    // const deviceIdentity = [
    //   {
    //     id: '9eea9f2d-dd70-4010-9e14-43176e5d8bdf',
    //     manufacturer: 'daxin',
    //     type: 'smart-toilet',
    //   },
    // ]
    getDeviceIdentity: (state) => ({ manufacturer, type }) => {
      console.log('getDeviceIdentify:', { manufacturer, type })
      let result = state.deviceIdentity.filter(item => (item.manufacturer === manufacturer && (type === '' || item.type === type)));
      result = result.map(item => {
        item.name = state.deviceTypeList.find(deviceType => deviceType.code === item.type)?.name;
        return item;
      })

      console.log('getDeviceIdentity', result);
      return result;
    },

    getDeviceProperties: (state) => (id) => {
      return state.deviceProperties.find(item => item.genericId === id);
    },

    getDeviceEvents: (state) => (id) => {
      return state.deviceEvents.find(item => item.genericId === id);
    }
  }
}