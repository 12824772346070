import { UbusRequest } from "@/helper/ubus"

export default {
    namespaced: true,
    state() {
      return {
        username: "api",
        password: "66483365",
        session_id: "00000000000000000000000000000000",
        url: "http://192.168.33.56/ubus/"  
      }
    },
    mutations: {
    },
    actions: { 
      async ubusLogin(context) {
        let username = context.state.username
        let password = context.state.password
        let timeout = 3600
        let session = '00000000000000000000000000000000'
        let argument = {namespace: 'session', procedure: 'login', data: { username, password, timeout, session }}
        const data = await UbusRequest(context.state.url, argument)  
        console.log(data.result[1].ubus_rpc_session, data)
        context.state.session_id = data.result[1].ubus_rpc_session
        return data.result[1].ubus_rpc_session
      },
      async getDeviceInfo(context ,id) {
        let token = await context.dispatch('ubusLogin')
        console.log('token', token, id)
  
        let params = {opt:"get",table:"device_info",filter:{where:"targetTemp > ? and user_id = ?",param:[28,id]}}
        let argument = {token: token, namespace: 'device', procedure: 'get', data: params}
        // const data = await UbusRequest(context.rootState.ubus.url, argument)  
        const data = await UbusRequest(context.state.url, argument)  

        if (data.error?.code) {
          console.log("error:", data.error.code, data.error.message)
        } else {
          
          data.result[1].result.forEach((info)=>{
            let target = context.rootState.device.userList.filter(d=>d.id == info.user_id)
            if (target.length > 0) {
              let deviceInfo = target[0].deviceInfo
              deviceInfo.urine = parseInt(info.urine)
              deviceInfo.stool = parseInt(info.stool)
              deviceInfo.targetTemp = parseFloat(info.targetTemp)
              deviceInfo.currentTemp = parseFloat(info.currentTemp)
              deviceInfo.operating = (info.operating === '1')
            }
          })
        }

      },
    },
    getters: {
       
    }
  }