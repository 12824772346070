<template>
  <div>
    <div class="text-center">{{ $t("smart-toliet") }}</div>
    <hr />
    <div class="row mb-4">
      <div class="col-4">{{ $t('number') }}</div>
      <div class="col-4 text-center">{{ this.$props.deviceNo }}</div>
      <div class="col-4 text-end">
        <span
          v-if="this.$props.deviceInfo?.operating === true"
          class="badge bg-primary"
          >{{ $t("device.operating") }}</span
        >
        <span v-else class="badge bg-secondary">{{ $t("device.stop") }}</span>
      </div>
      <div class="d-flex justify-content-between">
        <div class="mt-2 mb-2">
          {{ $t("device.target_water_temerature") }}：{{
            this.$props.deviceInfo.targetTemp
          }}
        </div>
        <div class="mt-2 mb-2">
          {{ $t("device.current_water_temperature") }}：{{
            this.$props.deviceInfo.currentTemp
          }}
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="row border-bottom text-center">
      <div class="col-3">{{ $t("statistic.serial") }}</div>
      <div class="col-3">{{ $t("statistic.time") }}</div>
      <div class="col-3">{{ $t("statistic.type") }}</div>
      <div class="col-3">{{ $t("statistic.weight") }}</div>
    </div>
    <div class="scrollable">
      <div
        v-for="(log, index) in displayData.list"
        :key="index"
        class="row border-bottom text-center"
      >
        <div class="col-3">{{ index + 1 }}</div>
        <div class="col-3">{{ log.time }}</div>
        <div class="col-3">{{ $t(log.type) }}</div>
        <div class="col-3">{{ log.weight }}</div>
      </div>
    </div>

    <div class="d-flex justify-content-between text-center mt-2">
      <div>
        <button
          type="button"
          class="btn btn-primary btn-sm px-2"
          :class="{ 'btn-secondary': !hasPrev }"
          :disabled="!hasPrev"
          @click="movePrev"
        >
          <font-awesome-icon icon="angle-left" />
        </button>
      </div>
      <div>{{ displayData.date }}</div>
      <div>
        <button
          type="button"
          class="btn btn-primary btn-sm px-2"
          :class="{ 'btn-secondary': !hasNext }"
          :disabled="!hasNext"
          @click="moveNext"
        >
          <font-awesome-icon icon="angle-right" />
        </button>
      </div>
    </div>

    <hr />

    <div class="d-flex justify-content-between">
      <div class="info-group">
        <button type="button" class="btn btn-secondary btn-sm">
          {{ $t("stool") }} {{ $t("count") }}
          <span class="badge bg-light text-primary">{{ stoolCount }}</span>
        </button>

        <button type="button" class="btn btn-warning btn-sm">
          {{ $t("urine") }} {{ $t("count") }}
          <span class="badge bg-light text-primary">{{ urineCount }}</span>
        </button>
      </div>
      <div class="info-group">
        <button
          type="button"
          class="btn btn-primary px-1 btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#medicalCareModal"
        >
          {{ $t("remark") }}
        </button>
        <button
          type="button"
          class="btn btn-danger px-1 btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#alertSettingModal"
        >
        {{ $t("settings") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  onBeforeMount,
  toRefs,
} from "vue";

export default defineComponent({
  name: "LogList",
  props: {
    list: {
      type: Array,
    },
    deviceNo: {
      type: String,
    },
    deviceInfo: {
      type: Object,
    },
  },
  setup(props) {
    // console.log('====LogList===')
    // console.log( props )

    let index = ref(0);

    let { list } = toRefs(props);
    list.value.sort((a, b) => (a.date < b.date ? 1 : b.date < a.date ? -1 : 0));
    // console.log('-------------------list', list.value)
    onBeforeMount(() => {
      // console.log('onBeforeMount @logList')
    });
    onMounted(() => {
      // console.log('onMounted @logList')
      list.value.sort((a, b) =>
        a.date < b.date ? 1 : b.date < a.date ? -1 : 0
      );

      // displayData.value.list.forEach((item)=>{ console.log('------->>>>>>>item', item.type) })
    });

    const displayData = computed(() => {
      // let data = props.list[index.value]
      let data = list.value[index.value];
      // console.log('===========displayData', data)
      return data;
    });

    const hasPrev = computed(() => {
      // console.log('hasPrev==========', index.value, list.value.length, list.value.length > 0, index.value !== (list.value.length - 1))
      return props.list.length > 0 && index.value !== props.list.length - 1;
    });
    const hasNext = computed(() => {
      return props.list.length > 0 && index.value > 0;
    });

    const stoolCount = computed(() => {
      let count = 0;
      displayData.value.list.forEach((item) => {
        count += item.type === "大便" ? 1 : 0;
      });
      return count;
    });
    const urineCount = computed(() => {
      let count = 0;
      displayData.value.list.forEach((item) => {
        count += item.type === "小便" ? 1 : 0;
      });
      return count;
    });

    const movePrev = () => {
      index.value++;
    };
    const moveNext = () => {
      index.value--;
    };

    return {
      displayData,
      hasPrev,
      hasNext,
      movePrev,
      moveNext,
      stoolCount,
      urineCount,
    };
  },
});
</script>


<style>
</style>