import dateFormat from "dateformat";
import i18n from '@/language'

export default {
  namespaced: true,
  state() {

    let getrandomtime = function () {
      return dateFormat(
        +new Date() - Math.floor(Math.random() * 360000) + 30000,
        "yyyy-mm-dd HH:MM:ss"
      );
    };

    const medicalCareModalList = [
      {
        id: "medicalCareModal",
        lists: [
          {
            head: "翻身拍背",
            body: "於2022-06-28 10:30:28由照服員執行翻身<br />預定下次翻身拍背時間：<font color='red'>2022-06-28 21:00:00</font>",
            i18n: 'turning-tapping',
            params: { datetime: '2022-06-28 10:30:28', nextDatetime: '2022-06-28 21:00:00' },
          },
          {
            head: "飲食記錄",
            body: "於" + getrandomtime() + "管灌：「<font color='red'>亞培安素</font>」計 170 ml <br />本日管灌飲食量共計 170 ml。",
            i18n: 'nutrition-input',
            params: { datetime: getrandomtime(), amount: 150, todayAmount: 150 },
            codes: { method: "tube-feeding", type: 'ensure', unit: 'measure.ml', "todayUnit": "measure.ml" }
          },
          {
            // 於{datetime}餵食：「{type}」計 {amount} {unit}，本日飲水量共計  {todayAmount} {todayUnit}，本日尚需飲水量  {reqiuredAmount} {requiredUnit}
            head: "飲水記錄",
            params: { datetime: getrandomtime(), amount: 250, todayAmount: 1250, reqiuredAmount: 750 },
            body: "於" + getrandomtime() + "餵食：「<font color='red'>飲用水</font>」計 250 cc<br />本日飲水量共計 1250 cc，<font color='red'>本日尚需飲水量 750cc</font>",
            i18n: 'drinking-input',
            codes: { type: 'water', unit: 'measure.ml', todayUnit: "measure.ml", requiredUnit: "measure.ml" }
          },
          {
            // 藥名： {medicineName}、反應：{symptom}
            head: "藥物過敏",
            i18n: 'medical-allergy',
            body: "藥名： <font color='red'>allopurinol</font>、反應：<font color='red'>紅疹發癢</font>",
            params: { medicineName: 'allopurinol' },
            codes: { symptom: "itchy-red-rash" }
          },
          {
            head: "Covid-19疫苗資訊",
            body: `於2021年07月30日接種第一劑疫苗：BNT<br />
            於2021年10月24日接種第二劑疫苗：BNT<br />
            於2022年01月20日接種第三劑疫苗：莫德納(Moderna)<br />`,
            i18n: 'covid19-vaccinate-history',
            params: [
              { date: '2021-07-30', number: 1, type: "BNT" },
              { date: '2021-10-24', number: 2, type: "BNT" },
              { date: '2022-01-20', number: 3, type: "Moderna" },
            ],
          },
        ],
      },
    ];

    const roomList = [
      {
        id: 1,
        no: 1,
        name: 'ooxx',
        deviceCount: 3,
        hasAlert: true,
        hasWarning: false,
        lastUpdateTime: getrandomtime(),
      },
      {
        id: 2,
        no: 2,
        deviceCount: 2,
        hasAlert: false,
        lastUpdateTime: getrandomtime(),
      },
      {
        id: 5,
        no: 5,
        deviceCount: 3,
        hasAlert: true,
        lastUpdateTime: getrandomtime(),
      },
      {
        id: 7,
        no: 7,
        deviceCount: 2,
        hasWarning: true,
        lastUpdateTime: getrandomtime(),
      },
    ];

    const userList = [
      {
        deviceNo: "DX001",
        name: "朱○豐",
        gender: "男",
        bedNo: 2,
        roomId: 1,
        status: ["臥床1"],
        alerts: [],
        warnings: [],
        lastUpdateTime: getrandomtime(),
        log: [
          {
            date: "2021/10/31",
            list: [
              { time: "09:56", type: "urine", weight: 162 },
              { time: "12:36", type: "urine", weight: 110 },
              { time: "13:55", type: "urine", weight: 141 },
            ],
          },
          {
            date: "2021/11/1",
            list: [
              { time: "08:36", type: "stool", weight: 595 },
              { time: "14:56", type: "urine", weight: 150 },
              { time: "22:21", type: "urine", weight: 140 },
            ],
          },
        ],
        deviceInfo: {
          urine: 3,
          stool: 1,
          targetTemp: 33,
          currentTemp: 29.5,
          operating: true,
        },
      }, {
        deviceNo: "DX002",
        name: "王○明",
        gender: "男",
        roomId: 1,
        bedNo: 3,
        status: ["臥床"],
        alerts: [
          { //$t('alert_title', {err_type: $t('waste-bucket-misplaced')})
            title: i18n.global.t('alert_title', { err_type: i18n.global.t('waste-bucket-misplaced') }),
            // title: "設備發生「污桶位移」告警",
            type: "waste-bucket-misplaced",
            detail: i18n.global.t('waste-bucket-misplaced-description', { room_no: 1, bed_no: 3, device_no: "DX002" })
            //   detail: `
            //   位於「<font color="red"><strong>1號房-3號床</strong></font>」
            //   設備編號「<font color="red"><strong>DX002</strong></font>」
            //   設備發生污桶污桶位移告警，每日晚間23:00 ~ 23:59為清潔人員統一清理污桶時間
            //   若非此時段發生告警，請立即前往處理。
            // `,
          },
        ],
        warnings: [],
        lastUpdateTime: getrandomtime(),
        log: [
          {
            date: "2021/10/31",
            list: [
              { time: "02:08", type: "urine", weight: 120 },
              { time: "07:06", type: "urine", weight: 193 },
              { time: "09:53", type: "urine", weight: 100 },
              { time: "12:53", type: "stool", weight: 592 },
              { time: "13:13", type: "urine", weight: 136 },
              { time: "19:54", type: "urine", weight: 190 },
            ],
          },
          {
            date: "2021/11/1",
            list: [
              { time: "01:00", type: "urine", weight: 141 },
              { time: "02:23", type: "urine", weight: 162 },
              { time: "07:37", type: "urine", weight: 187 },
              { time: "10:54", type: "stool", weight: 409 },
              { time: "18:57", type: "urine", weight: 195 },
            ],
          },
          {
            date: "2021/11/2",
            list: [
              { time: "03:44", type: "urine", weight: 158 },
              { time: "08:38", type: "urine", weight: 192 },
              { time: "09:15", type: "urine", weight: 175 },
              { time: "13:52", type: "urine", weight: 193 },
              { time: "14:31", type: "urine", weight: 171 },
              { time: "22:33", type: "urine", weight: 132 },
            ],
          },
          {
            date: "2021/11/3",
            list: [
              { time: "00:26", type: "urine", weight: 135 },
              { time: "06:26", type: "urine", weight: 115 },
              { time: "18:34", type: "urine", weight: 183 },
              { time: "22:22", type: "urine", weight: 136 },
              { time: "22:30", type: "urine", weight: 194 },
              { time: "23:47", type: "urine", weight: 180 },
            ],
          },
          {
            date: "2021/11/4",
            list: [
              { time: "02:51", type: "urine", weight: 115 },
              { time: "10:24", type: "urine", weight: 112 },
              { time: "20:40", type: "urine", weight: 107 },
            ],
          },
        ],
        deviceInfo: {
          urine: 2,
          stool: 0,
          targetTemp: 31,
          currentTemp: 29.3,
          operating: false,
        },
      },
      {
        deviceNo: "DX003",
        name: "賴○棟",
        gender: "男",
        roomId: 1,
        bedNo: 5,
        status: ["臥床"],
        alerts: [],
        warnings: [],
        lastUpdateTime: getrandomtime(),
        log: [
          {
            date: "2021/10/31",
            list: [{ time: "08:26", type: "urine", weight: 152 }],
          },
          {
            date: "2021/11/1",
            list: [
              { time: "02:14", type: "urine", weight: 101 },
              { time: "11:01", type: "urine", weight: 199 },
              { time: "20:21", type: "stool", weight: 413 },
            ],
          },
          {
            date: "2021/11/2",
            list: [
              { time: "03:35", type: "urine", weight: 142 },
              { time: "03:39", type: "urine", weight: 181 },
              { time: "05:23", type: "urine", weight: 196 },
              { time: "16:52", type: "urine", weight: 149 },
              { time: "20:30", type: "urine", weight: 191 },
            ],
          },
          {
            date: "2021/11/3",
            list: [],
          },
          {
            date: "2021/11/4",
            list: [
              { time: "05:58", type: "urine", weight: 145 },
              { time: "09:17", type: "urine", weight: 168 },
              { time: "10:38", type: "urine", weight: 117 },
              { time: "15:19", type: "urine", weight: 187 },
              { time: "20:19", type: "stool", weight: 424 },
            ],
          },
        ],
        deviceInfo: {
          urine: 2,
          stool: 1,
          targetTemp: 33,
          currentTemp: 32.2,
          operating: true,
        },
      },
      {
        deviceNo: "DX005",
        name: "趙○基",
        gender: "男",
        roomId: 2,
        bedNo: 6,
        status: ["健康", "自主行走"],
        alerts: [],
        warnings: [],
        lastUpdateTime: getrandomtime(),
        log: [
          {
            date: "2021/10/31",
            list: [
              { time: "01:41", type: "urine", weight: 142 },
              { time: "03:21", type: "urine", weight: 149 },
            ],
          },
          {
            date: "2021/11/1",
            list: [
              { time: "04:32", type: "urine", weight: 123 },
              { time: "07:12", type: "urine", weight: 168 },
              { time: "10:16", type: "urine", weight: 141 },
              { time: "11:54", type: "urine", weight: 155 },
              { time: "15:52", type: "urine", weight: 105 },
              { time: "15:57", type: "urine", weight: 123 },
              { time: "23:50", type: "urine", weight: 188 },
            ],
          },
          {
            date: "2021/11/2",
            list: [
              { time: "00:21", type: "stool", weight: 598 },
              { time: "07:10", type: "urine", weight: 153 },
              { time: "10:25", type: "urine", weight: 152 },
              { time: "12:45", type: "urine", weight: 125 },
              { time: "15:12", type: "urine", weight: 122 },
              { time: "18:58", type: "stool", weight: 462 },
              { time: "21:24", type: "stool", weight: 506 },
            ],
          },
          {
            date: "2021/11/3",
            list: [
              { time: "07:14", type: "urine", weight: 189 },
              { time: "12:49", type: "urine", weight: 170 },
            ],
          },
          {
            date: "2021/11/4",
            list: [
              { time: "00:25", type: "urine", weight: 113 },
              { time: "00:31", type: "urine", weight: 111 },
              { time: "02:00", type: "urine", weight: 123 },
              { time: "02:08", type: "urine", weight: 172 },
              { time: "19:11", type: "urine", weight: 141 },
              { time: "22:46", type: "stool", weight: 585 },
            ],
          },
        ],
        deviceInfo: {
          urine: 3,
          stool: 1,
          targetTemp: 33,
          currentTemp: 31.8,
          operating: true,
        },
      },
      {
        deviceNo: "DX006",
        name: "徐○旺",
        gender: "男",
        roomId: 2,
        bedNo: 9,
        status: ["臥床"],
        alerts: [],
        warnings: [],
        lastUpdateTime: getrandomtime(),
        log: [
          {
            date: "2021/10/31",
            list: [
              { time: "07:27", type: "stool", weight: 485 },
              { time: "09:44", type: "urine", weight: 119 },
              { time: "11:51", type: "urine", weight: 164 },
              { time: "20:58", type: "urine", weight: 111 },
            ],
          },
          {
            date: "2021/11/1",
            list: [
              { time: "04:55", type: "urine", weight: 196 },
              { time: "14:46", type: "urine", weight: 196 },
              { time: "23:57", type: "urine", weight: 138 },
            ],
          },
          {
            date: "2021/11/2",
            list: [
              { time: "01:03", type: "urine", weight: 178 },
              { time: "01:42", type: "stool", weight: 524 },
              { time: "06:29", type: "urine", weight: 187 },
              { time: "09:21", type: "urine", weight: 182 },
              { time: "20:08", type: "urine", weight: 158 },
            ],
          },
          {
            date: "2021/11/3",
            list: [
              { time: "08:31", type: "urine", weight: 132 },
              { time: "08:32", type: "stool", weight: 520 },
              { time: "12:08", type: "urine", weight: 144 },
              { time: "15:17", type: "urine", weight: 107 },
              { time: "17:59", type: "urine", weight: 108 },
              { time: "20:15", type: "urine", weight: 155 },
              { time: "22:28", type: "urine", weight: 185 },
            ],
          },
          {
            date: "2021/11/4",
            list: [
              { time: "04:49", type: "stool", weight: 406 },
              { time: "06:02", type: "urine", weight: 161 },
              { time: "07:02", type: "stool", weight: 447 },
              { time: "09:42", type: "urine", weight: 190 },
              { time: "13:19", type: "urine", weight: 185 },
              { time: "14:26", type: "urine", weight: 120 },
              { time: "14:31", type: "stool", weight: 469 },
            ],
          },
        ],
        deviceInfo: {
          urine: 1,
          stool: 1,
          targetTemp: 33,
          currentTemp: 32.5,
          operating: true,
        },
      },
      {
        deviceNo: "DX007",
        name: "謝○玉",
        gender: "女",
        roomId: 5,
        bedNo: 18,
        status: ["離床", "健康", "輔具行走"],
        alerts: [],
        warnings: [],
        lastUpdateTime: getrandomtime(),
        log: [
          {
            date: "2021/10/31",
            list: [
              { time: "07:10", type: "urine", weight: 170 },
              { time: "11:46", type: "urine", weight: 151 },
              { time: "16:01", type: "urine", weight: 124 },
              { time: "17:32", type: "stool", weight: 413 },
              { time: "19:08", type: "urine", weight: 195 },
            ],
          },
          {
            date: "2021/11/1",
            list: [
              { time: "04:58", type: "urine", weight: 188 },
              { time: "05:07", type: "urine", weight: 165 },
              { time: "07:24", type: "urine", weight: 109 },
              { time: "10:33", type: "stool", weight: 446 },
              { time: "10:38", type: "urine", weight: 132 },
              { time: "11:03", type: "stool", weight: 433 },
              { time: "23:16", type: "urine", weight: 103 },
            ],
          },
          {
            date: "2021/11/2",
            list: [
              { time: "02:13", type: "urine", weight: 167 },
              { time: "05:23", type: "stool", weight: 417 },
              { time: "13:20", type: "urine", weight: 176 },
            ],
          },
          {
            date: "2021/11/3",
            list: [
              { time: "14:30", type: "urine", weight: 187 },
              { time: "22:13", type: "urine", weight: 115 },
              { time: "23:10", type: "urine", weight: 167 },
            ],
          },
          {
            date: "2021/11/4",
            list: [
              { time: "00:08", type: "urine", weight: 165 },
              { time: "01:57", type: "stool", weight: 538 },
              { time: "02:22", type: "urine", weight: 130 },
            ],
          },
        ],
        deviceInfo: {
          urine: 5,
          stool: 1,
          targetTemp: 32,
          currentTemp: 30.7,
          operating: false,
        },
      },
      {
        deviceNo: "DX008",
        name: "邱○○鴦",
        gender: "女",
        roomId: 5,
        bedNo: 19,
        status: ["臥床"],
        alerts: [
          {
            title: "超過 3 天沒有大便發生",
            detail: `
              位於「<font color="red"><strong>5號房-19號床</strong></font>」
              設備編號「<font color="red"><strong>DX008</strong></font>」
              自<font color="red"> 2021/11/02 19:45 </font> 已逾
              <font color="red"> 3 天 </font>
              未有<strong>大便</strong>發生，請根據使用者情況通知相關人員進行進一步了解。
          `,
          },
        ],
        warnings: [],
        lastUpdateTime: getrandomtime(),
        log: [
          {
            date: "2021/10/31",
            list: [
              { time: "05:11", type: "urine", weight: 122 },
              { time: "08:30", type: "stool", weight: 441 },
              { time: "16:37", type: "urine", weight: 144 },
              { time: "21:44", type: "stool", weight: 431 },
              { time: "22:24", type: "urine", weight: 116 },
            ],
          },
          {
            date: "2021/11/1",
            list: [
              { time: "00:44", type: "urine", weight: 145 },
              { time: "02:13", type: "urine", weight: 151 },
              { time: "12:15", type: "stool", weight: 504 },
              { time: "14:47", type: "urine", weight: 197 },
              { time: "23:10", type: "urine", weight: 126 },
            ],
          },
          {
            date: "2021/11/2",
            list: [
              { time: "04:01", type: "urine", weight: 169 },
              { time: "16:51", type: "urine", weight: 114 },
              { time: "23:57", type: "urine", weight: 157 },
              { time: "19:45", type: "stool", weight: 421 },
            ],
          },
          {
            date: "2021/11/3",
            list: [
              { time: "08:08", type: "urine", weight: 191 },
              { time: "08:21", type: "urine", weight: 124 },
              { time: "19:19", type: "urine", weight: 161 },
              { time: "23:32", type: "urine", weight: 153 },
            ],
          },
          {
            date: "2021/11/4",
            list: [
              { time: "00:25", type: "urine", weight: 138 },
              { time: "09:47", type: "urine", weight: 123 },
              { time: "13:24", type: "urine", weight: 178 },
              { time: "15:23", type: "urine", weight: 173 },
              { time: "15:41", type: "urine", weight: 169 },
              { time: "17:21", type: "urine", weight: 126 },

              { time: "19:52", type: "urine", weight: 125 },
            ],
          },
        ],
        deviceInfo: {
          urine: 2,
          stool: 0,
          targetTemp: 33,
          currentTemp: 32,
          operating: true,
        },
      },
      {
        deviceNo: "DX009",
        name: "熊○嫌",
        gender: "女",
        roomId: 5,
        bedNo: 21,
        status: ["臥床", "呼吸器"],
        alerts: [],
        warnings: [],
        lastUpdateTime: getrandomtime(),
        log: [
          {
            date: "2021/10/31",
            list: [
              { time: "00:20", type: "urine", weight: 100 },
              { time: "07:06", type: "urine", weight: 197 },
              { time: "15:45", type: "urine", weight: 143 },
              { time: "20:09", type: "urine", weight: 166 },
              { time: "20:52", type: "urine", weight: 163 },
            ],
          },
          {
            date: "2021/11/1",
            list: [
              { time: "01:20", type: "urine", weight: 161 },
              { time: "12:37", type: "urine", weight: 196 },
              { time: "14:43", type: "urine", weight: 137 },
              { time: "17:01", type: "urine", weight: 177 },
            ],
          },
          {
            date: "2021/11/2",
            list: [
              { time: "00:16", type: "stool", weight: 460 },
              { time: "02:46", type: "urine", weight: 131 },
              { time: "12:14", type: "urine", weight: 178 },
              { time: "13:27", type: "urine", weight: 168 },
              { time: "15:22", type: "stool", weight: 413 },
              { time: "17:11", type: "urine", weight: 139 },
            ],
          },
          {
            date: "2021/11/3",
            list: [
              { time: "01:42", type: "urine", weight: 105 },
              { time: "02:05", type: "urine", weight: 150 },
              { time: "09:06", type: "urine", weight: 112 },
              { time: "09:30", type: "urine", weight: 153 },
              { time: "09:36", type: "stool", weight: 594 },
              { time: "13:50", type: "urine", weight: 162 },
              { time: "16:46", type: "urine", weight: 170 },
              { time: "18:18", type: "urine", weight: 121 },
              { time: "20:14", type: "urine", weight: 145 },
            ],
          },
          {
            date: "2021/11/4",
            list: [
              { time: "02:20", type: "urine", weight: 181 },
              { time: "03:03", type: "stool", weight: 497 },
              { time: "09:21", type: "urine", weight: 148 },
              { time: "14:05", type: "urine", weight: 186 },
              { time: "19:32", type: "urine", weight: 151 },
              { time: "22:56", type: "urine", weight: 133 },
              { time: "23:43", type: "urine", weight: 125 },
            ],
          },
        ],
        deviceInfo: {
          urine: 3,
          stool: 2,
          targetTemp: 33,
          currentTemp: 32.5,
          operating: true,
        },
      },
      {
        deviceNo: "DX010",
        name: "陳○妹",
        gender: "女",
        roomId: 7,
        bedNo: 31,
        status: ["健康", "自主行走"],
        alerts: [],
        warnings: [
          {
            title: "水箱溫度偏低預警",
            detail: `
              位於「<font color="red"><strong>7號房-31號床</strong></font>」
              設備編號「<font color="red"><strong>DX010</strong></font>」
              設定水溫<font color="red">33</font>度目前水溫<font color="red">27.2</font>度，
              若持留意水溫過低請連絡相關人員進行了解。`,
          },
        ],
        lastUpdateTime: getrandomtime(),
        log: [
          {
            date: "2021/10/31",
            list: [
              { time: "06:46", type: "urine", weight: 192 },
              { time: "10:01", type: "urine", weight: 124 },
              { time: "13:39", type: "stool", weight: 534 },
              { time: "16:50", type: "urine", weight: 137 },
              { time: "23:13", type: "urine", weight: 187 },
            ],
          },
          {
            date: "2021/11/1",
            list: [
              { time: "04:29", type: "stool", weight: 588 },
              { time: "05:04", type: "urine", weight: 145 },
              { time: "13:03", type: "stool", weight: 583 },
              { time: "15:05", type: "urine", weight: 195 },
              { time: "16:09", type: "stool", weight: 569 },
              { time: "19:43", type: "urine", weight: 127 },
            ],
          },
          {
            date: "2021/11/2",
            list: [
              { time: "04:36", type: "urine", weight: 120 },
              { time: "15:01", type: "urine", weight: 149 },
              { time: "03:04", type: "urine", weight: 173 },
              { time: "03:46", type: "stool", weight: 430 },
              { time: "14:54", type: "urine", weight: 120 },
              { time: "17:59", type: "stool", weight: 419 },
            ],
          },
          {
            date: "2021/11/3",
            list: [
              { time: "03:04", type: "urine", weight: 173 },
              { time: "03:46", type: "stool", weight: 430 },
              { time: "14:54", type: "urine", weight: 120 },
              { time: "17:59", type: "stool", weight: 419 },
            ],
          },
          {
            date: "2021/11/4",
            list: [
              { time: "03:22", type: "urine", weight: 173 },
              { time: "03:30", type: "stool", weight: 445 },
              { time: "09:27", type: "urine", weight: 190 },
              { time: "11:17", type: "urine", weight: 108 },
              { time: "18:55", type: "urine", weight: 118 },
            ],
          },
        ],
        deviceInfo: {
          urine: 1,
          stool: 1,
          targetTemp: 33,
          currentTemp: 27.2,
          operating: false,
        },
      },
      {
        deviceNo: "DX011",
        name: "鄭○琴",
        gender: "女",
        roomId: 7,
        bedNo: 32,
        status: ["臥床"],
        alerts: [],
        warnings: [],
        lastUpdateTime: getrandomtime(),
        log: [
          {
            date: "2021/10/31",
            list: [
              { time: "05:56", type: "stool", weight: 509 },
              { time: "07:06", type: "urine", weight: 156 },
              { time: "12:39", type: "urine", weight: 165 },
              { time: "16:41", type: "urine", weight: 142 },
              { time: "19:43", type: "urine", weight: 162 },
            ],
          },
          {
            date: "2021/11/1",
            list: [
              { time: "04:05", type: "urine", weight: 179 },
              { time: "13:11", type: "stool", weight: 596 },
              { time: "15:19", type: "urine", weight: 176 },
              { time: "17:17", type: "stool", weight: 584 },
            ],
          },
          {
            date: "2021/11/2",
            list: [
              { time: "00:47", type: "urine", weight: 187 },
              { time: "02:03", type: "urine", weight: 120 },
              { time: "03:35", type: "urine", weight: 100 },
              { time: "13:22", type: "urine", weight: 106 },
              { time: "18:20", type: "urine", weight: 147 },
            ],
          },
          {
            date: "2021/11/3",
            list: [
              { time: "10:10", type: "urine", weight: 148 },
              { time: "16:41", type: "urine", weight: 103 },
            ],
          },
          {
            date: "2021/11/4",
            list: [
              { time: "03:28", type: "urine", weight: 123 },
              { time: "08:26", type: "urine", weight: 120 },
              { time: "10:17", type: "urine", weight: 140 },
              { time: "14:49", type: "urine", weight: 149 },
              { time: "19:43", type: "urine", weight: 133 },
            ],
          },
        ],
        deviceInfo: {
          urine: 3,
          stool: 1,
          targetTemp: 33,
          currentTemp: 32,
          operating: true,
        },
      },
    ];
    // alertList: [
    //   {
    //     level: "critical",
    //     title: "污桶位移",
    //     message:
    //       "位於「1號房-3號床」設備編號「DX002」發生「污桶位移」告警 請立即進行處理",
    //   },
    //   {
    //     level: "critical",
    //     title: "排遺數據異常",
    //     message:
    //       "位於「5號房-19號床」設備編號「DX011」發生「生理數據異常」請連絡相關人員進行處理",
    //   },
    // ],

    // modalList: [
    //   {
    //     id: "alertDetail1Modal",
    //     title: "生理數據異常",
    //     content: `發生時間：2021/10/27 6:03:19
    //         <hr />
    //         「101房號」編號「一號床」統計自
    //         <font color="red"> 2021/10/27 03:00:07 </font> 已逾
    //         <font color="red"> 180 分鐘 </font>
    //         未有<strong>大便</strong>發生，請根據使用者情況通知相關人員進行進一步了解。`,
    //   },
    //   {
    //     id: "alertDetail2Modal",
    //     title: "生理數據異常",
    //     content: `發生時間：2021/10/27 6:03:19
    //     <hr />
    //     「101房號」編號「一號床」統計自
    //     <font color="red"> 2021/10/27 03:00:07 </font> 已逾
    //     <font color="red"> 180 分鐘 </font>
    //     未有<strong>大便</strong>發生，請根據使用者情況通知相關人員進行進一步了解。`,
    //   },
    // ],
    // end of modalList
    // toastLists: [
    //   {
    //     title: "設備狀態異動通知...",
    //     content: "設備「DX001」已上線。",
    //     cfg: { closeAfter: 10, action: "dispose" },
    //   },
    //   {
    //     title: "設備狀態異動通知...",
    //     content: "設備「DX003」已離線。",
    //     cfg: { closeAfter: 10, action: "dispose" },
    //   },
    //   {
    //     title: "設備編號(DX010)水溫過低...",
    //     content: `位於「<font color="red"><strong>7號房-31號床</strong></font>」
    //         設備編號「<font color="red"><strong>DX010</strong></font>」
    //         設定水溫<font color="red">33</font>度目前水溫<font color="red">27.2</font>度，
    //       若持留意水溫過低請連絡相關人員進行了解。`,
    //     cfg: { closeAfter: -1, timestamp: new Date() - 7200000 },
    //   },
    // ],

    const devices = [
      {
        id: "cc5c7fb9-9d39-467a-8ab9-726d3bac078b",
        name: "DX-001",
        type: "smart-toilet",
        patientId: '',
        wardId: '',
      },
      {
        id: "979c50e6-bba5-4205-9c73-e576309e9863",
        name: "DX-002",
        type: "smart-toilet",
        patientId: '',
        wardId: '',
      },
      {
        id: "b1743347-7b08-462b-a837-a2ba6c4c56ff",
        name: "DX-003",
        type: "smart-toilet",
        patientId: '',
        wardId: '',
      },
    ];

    return {
      targetRoom: 0,
      medicalCareModalList,
      userList,
      roomList,
      //
      devices

    }
  },
  mutations: { // $store.commit('increment',1) // 之後要把新增/修改項目至 state 放這裡 ... 
    setTargetRoom(state, roomId) { // eslint-disable-line
      console.log('setTargetRoom----------', roomId, this)
      state.targetRoom = parseInt(roomId)
    },
    addToast(state, ...context) {
      console.log(state, context)
    }
  },
  actions: { // asynchronized request $store.dispatch('addRandomNumber')
    // 之後會把資料放到db撈取，目前先不處理
    async addRandomNumber(context) { // context is everything inside our store
      console.log('addRandomNumber Start')
      console.log('context', context)
      const url = 'https://www.random.org/integers/?num=1&min=1&max=100&col=1&base=10&format=plain&rnd=new'
      const response = await fetch(url)
      console.log(response.ok, response.status)
      const number = await response.text()
      console.log({ number })
      // context.commit('increment', parseInt(number))
    },
    loadDataFromDb(interval) {
      interval = parseInt(interval) || 1 // simulator loading time
      interval *= 1000
      console.log("wait for " + interval + " milliseconds @ Main.vue");
      return new Promise((resolve) => {
        setTimeout(function () {
          resolve('data finish loading');
        }, interval);
      });
    }
  },
  getters: {
    // roomEixsts(state) {
    //   return state.targetRoom * 2
    // },
    // roomNumbers: state => {
    //   console.log('roomNumbers');
    //   let result = state.roomList.map((room) => {
    //     room.name = room.no + i18n.global.t('room-number-describer');
    //     console.log('room::::::::', room);

    //     return room;
    //   })
    //   return result;
    // },
    roomExists: state => {
      return state.roomList.filter((room) => room.id === state.targetRoom).length > 0
    },
    filterdRoom: state => {
      if (state.targetRoom === 0) {
        return state.roomList.map(room => {
          // console.log('i18n.global.locale:', i18n.global);
          room.name = i18n.global.t('room-number-describer', { room_no: room.no })
          // if (i18n.global.locale.value === 'en-US') {
          //   room.name = i18n.global.t('room-number-describer') + ' ' + room.no;
          // } else {
          //   room.name = room.no + i18n.global.t('room-number-describer');
          // }

          return room;
        })
      } else {
        return state.roomList.filter((room => {
          if (room.id === state.targetRoom) {
            room.name = i18n.global.t('room-number-describer', { room_no: room.no })
            return true;
          }
        }));
      }
    },
    filterdUser: state => {
      console.log('filterdUser--------------', state)
      return state.userList.filter((user) => parseInt(user.roomId) === state.targetRoom)
    },

    queryDevice: state => filter => {
      console.log(filter);
      return state.devices;
    }
  }
}