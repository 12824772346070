<template>
  <div class="modal fade" :id="modalId" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("medical-remarks") }}</h5>
          <!-- 註記 -->
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <bs-card
              v-for="(item, index) in dataLists"
              :key="index"
              :head="item.head"
              :body="item.body"
            />
          </div>
          <nav aria-label="Page navigation example pt-1">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-link" href="#">Previous</a>
              </li>
              <li class="page-item"><a class="page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item"><a class="page-link" href="#">Next</a></li>
            </ul>
          </nav>
        </div>

        <div class="modal-footer">
          <slot name="footer" />
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{ $t("close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import BsCard from "@/components/bootstrap/BsCard.vue";
import i18n from "@/language";

export default defineComponent({
  name: "ComplianceModal",
  components: { BsCard },
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    console.log("props", props, props.data.id === "undefined");
    let modalId =
      props.data.id || "modal-" + Math.floor(Math.random() * 99999) + 11111;
    console.log("modalId", modalId);
    // let dataLists = [];

    const dataLists = computed(() => {
      let result = [];
      if (typeof props.data.lists === "object" && props.data.lists.length > 0) {
        // console.log('==============')
        props.data.lists.forEach((item) => {
          console.log("--------------", item);
          // if (item.head !== "undefined" && item.body !== "undefined") {
          //   result.push(item);
          // }
          let params;
          let body;
          if (item.i18n === "covid19-vaccinate-history") {
            //
            body = item.params
              .map((data) =>
                i18n.global.t("covid19-vaccinate-history-body", data)
              )
              .join("<br />");
          } else {
            params = { ...item.params };
            if (item.codes && typeof item.codes === "object") {
              Object.keys(item.codes).forEach((key) => {
                console.log("");
                let val = item.codes[key];
                params[key] = i18n.global.t(val);
              });
            }
            body = i18n.global.t(item.i18n + "-body", params);
          }
          console.log("--------------", params);
          let head = i18n.global.t(item.i18n);

          result.push({ head, body });
        });
      }
      return result;
    });

    return { props, modalId, dataLists };
  },
});
</script>

<style scoped>
</style>