<template>
  <div class="card" style="width: 100%">
    <div
      class="card-header touch-draggable"
      :class="{ 'bg-primary': bgPrimary, 'text-light': textLight }"
    >
      <div class="d-flex justify-content-between">
        <div>
          <span v-if="props.icon">
            <font-awesome-icon :icon="props.icon" />
          </span>
          {{ props.head }}
        </div>
        <div
          v-show="props.closeBtn"
          class="text-end"
          @click="handleBtnClick(props.id, 'close')"
        >
          <font-awesome-icon icon="xmark" />
        </div>
        <div
          v-show="props.editBtn"
          class="text-end"
          @click="handleBtnClick(props.id, 'edit')"
        >
          <font-awesome-icon icon="pen-to-square" />
        </div>
      </div>
    </div>
    <div class="card-body">
      <div v-html="props.body" />
      <slot name="body" />
    </div>
    <div v-show="props.foot" class="card-footer fs-xs text-end">
      {{ props.foot }}
    </div>
  </div>
</template>

<script>
//

import { defineComponent } from "vue";

export default defineComponent({
  props: {
    head: {
      required: true,
      // type: String,
    },
    body: {
      required: false,
      type: String,
    },
    foot: {
      required: false,
      // type: String
    },
    color: {
      default: "primary",
      type: String,
    },
    closeBtn: {
      type: Boolean,
      default() {
        return false;
      },
    },
    editBtn: {
      type: Boolean,
      default() {
        return false;
      },
    },
    id: {
      // type: Number,
      default: -1,
    },
    icon: {
      type: String,
      default: "",
    },
  },
  emits: ["closeClicked", "editClicked"],
  setup(props, { emit }) {
    // console.log("bscard-props", props);
    let bgPrimary = false;
    let textLight = false;
    if (props.color === "primary") {
      bgPrimary = true;
      textLight = true;
    }
    // console.log(props)
    const handleBtnClick = (id, type) => {
      console.log(id, type);
      emit(type + "Clicked", id);
    };

    return { props, bgPrimary, textLight, handleBtnClick };
  },
});
</script>

<style scoped>
</style>