<script>
export default {};
</script>
<template>
  <div class="fullscreen">
    <div class="d-flex container loader">
      <span><img src="/img/logo.png" height="40 px" class="logo"/></span>
      <span>大</span>
      <span>心</span>
      <span>生</span>
      <span>物</span>
      <span>科</span>
      <span>技</span>
      <span>關</span>
      <span>心</span>
      <span>您</span>
      <span>。</span>
    </div>
  </div>
</template>
<style scoped>
.logo {
  border: 1px white solid;
  border-radius: 50%;
}
.fullscreen {
  width: 100vw;
  height: 100vh;
  background-color: #771326;
  margin: 0;
  padding: 0;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.loader span {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 30px;
  color: aliceblue;
  display: inline-block;
  transition: all 0.5s;
  animation: animate 2s infinite;
}
.loader span:nth-child(1) {
  animation-delay: 0.1s;
}
.loader span:nth-child(2) {
  animation-delay: 0.2s;
}
.loader span:nth-child(3) {
  animation-delay: 0.3s;
}
.loader span:nth-child(4) {
  animation-delay: 0.4s;
}
.loader span:nth-child(5) {
  animation-delay: 0.5s;
}
.loader span:nth-child(6) {
  animation-delay: 0.6s;
}
.loader span:nth-child(7) {
  animation-delay: 0.7s;
}
@keyframes animate {
  0% {
    color: aliceblue;
    transform: translateY(0);
    margin-left: 0;
  }
  25% {
    color: aliceblue;
    transform: translateY(-15px);
    margin-left: 10px;
    text-shadow: 0 15px 5px rgba(0, 0, 0, 1);
  }
  100% {
    color: aliceblue;
    transform: translateY(0);
    margin-left: 0;
  }
}
</style>