import { createI18n } from 'vue-i18n'

import zh from "./zh-TW.json";
import en from "./en-US.json";
import ja from "./ja-JP.json";

const numberFormats = {
    'en-US': {
      currency: {
        style: 'currency',
        currency: 'USD'
      }
    },
    'ja-JP': {
      currency: {
        style: 'currency',
        currency: 'JPY',
        currencyDisplay: 'symbol'
      }
    },
    'zh-TW': {
      currency: {
        style: 'currency',
        currency: 'TWD',
        currencyDisplay: 'symbol'
      }
    }
  }



const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem("locale") ?? "zh-TW",
    fallbackLocale: "zh-TW",
    globalInjection: true,
    warnHtmlMessage: false,
    messages: {
        "zh-TW": zh,
        "en-US": en,
        "ja-JP": ja
    },
    numberFormats,
});

export default i18n