<template>
  <div class="card">
    <div
      class="card-header text-center fs-5 bg-primary text-light"
      :class="{
        'bg-danger': props.hasAlert,
        'bg-warning': props.hasWarning,
        'text-dark': props.hasWarning,
      }"
      @click="selectRoom(this.$props.no)"
    >
      {{$t('room-no')}}：{{ props.name }}
    </div>
    <!-- {{$t('xxxx')}} -->
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <div>{{$t('device-count')}}：{{ props.deviceCount }}</div>
        <div>
          <font-awesome-icon
            v-show="props.hasAlert"
            icon="exclamation-triangle"
            class="text-danger blink_me"
          />
          <font-awesome-icon
            v-show="props.hasWarning"
            icon="info-circle"
            class="text-warning blink_me"
          />
        </div>
      </div>
    </div>
    <div class="card-footer fs-xs text-end">
      <div v-show="showUpdateTime">
        {{$t('last-update-time')}}：{{ props.lastUpdateTime }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "RoomDetail",
  props: {
    no: {
      required: true,
      type: Number,
    },
    name: {
      required: true,
      type: String,
    },
    deviceCount: {
      required: true,
      type: Number,
    },
    hasAlert: {
      default: false,
      type: Boolean,
    },
    hasWarning: {
      default: false,
      type: Boolean,
    },
    lastUpdateTime: {
      required: false,
      type: String,
    },
  },
  setup(props, { emit }) {
    console.log('----------------props', props)
    let showUpdateTime = false;
    if (props.lastUpdateTime !== "undefined") {
      showUpdateTime = true;
    }
    console.log("showUpdateTime", showUpdateTime);

    const selectRoom = (roomNo) => {
		console.log('selectRoom', roomNo)
      emit("onRoomChanged", roomNo);
    };

    return { props, showUpdateTime, selectRoom };
  },
});
</script>
    
<style>
</style>