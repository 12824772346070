<template>
  <!-- 最上層 選單 -->
  <header-bar class="header-block" />
  <!-- 第二層 選單 -->
  <menu-bar :list="menu" class="menu-block" />
  <!-- {{alertList}} -->
  <div class="alert-block">
    <alert-block />
  </div>
  <router-view />
  <!-- 左下角 按鍵 -->
  <float-btn />
  <!-- 左側 選單 -->
  <side-bar />
  <toast-area />
</template>

<script>
// import { useAxios } from '@vueuse/integrations'

import HeaderBar from "@/components/HeaderBar.vue";
import MenuBar from "@/components/MenuBar.vue";
import SideBar from "@/components/offcanvas/SideBar.vue";
import FloatBtn from "@/components/ui/FloatBtn.vue";
import AlertBlock from "@/components/ui/AlertBlock.vue";
import ToastArea from "@/components/ui/ToastArea.vue";
// import { useRoute } from "vue-router";
// import { watch } from "vue";

import { defineComponent, computed } from "vue"; // eslint-disable-line

export default defineComponent({
  app: "App",
  components: {
    // navbar
    HeaderBar,
    MenuBar,
    SideBar,
    FloatBtn,
    AlertBlock,
    ToastArea, // eslint-disable-line
  },

  async setup() {
    // 這邊在未來會用來呼叫API取得設定檔，暫時先延遲模擬載入資料...

    function timeoutPromise(interval, hasError) {
      console.log("wait for " + interval + " milliseconds @ Main.vue");
      return new Promise((resolve, reject) => {
        setTimeout(function () {
          //   resolve("done")
          if (!hasError) {
            resolve();
          } else {
            reject("資料庫連線失敗");
          }
        }, interval);
      });
    }

    let hasError = false;
    await timeoutPromise(300, hasError);

    let menu = [
      { route: "/", text: "資訊看版", translateCode: "dashboard" },
      { route: "/about", text: "關於大心", translateCode: "about-us" },
      // { route: "/login", text: "登入" },
    ];

    // alertList 先測試，之後搬到 子組件去

    return { menu };
  },
});
</script>

<style>
.header-block {
  position: sticky;
  top: 0px;
  z-index: 1039;
}
.menu-block {
  position: sticky;
  top: 50px;
  z-index: 1039;
}

.alert-block {
  position: sticky;
  top: 90px;
  z-index: 1039;
  background-color: #ffffff;
}
</style>