// import dateFormat from "dateformat";

// let getrandomtime = function () {
//   return dateFormat(
//     +new Date() - Math.floor(Math.random() * 360000) + 30000,
//     "yyyy-mm-dd HH:MM:ss"
//   );
// };

import { v4 as uuid } from 'uuid';
import i18n from '@/language'
import { time } from 'convert-units';

export default {
  namespaced: true,
  state() {
    const toastList = [
      {
        id: 'b1f56b42-3bc6-4bc1-bc43-5bc75e7cd44f',
        title: "設備狀態異動通知...",
        content: "設備「DX001」已上線。",
        params: { device_name: "DX001" },
        translate_name: { title: "toast-title-device-status-change", content: "toast-content-device-online" },
        cfg: { closeAfter: 5, action: "dispose", route: 'global' },
      },
      {
        id: '15fd129c-4fbe-4ea5-9321-325511c28854',
        title: "設備狀態異動通知...",
        content: "設備「DX003」已離線。",
        params: { device_name: "DX003" },
        translate_name: { title: "toast-title-device-status-change", content: "toast-content-device-offline" },
        cfg: { closeAfter: 10, action: "dispose", route: 'Home' },
      },
      {
        id: '9198ef17-816c-468c-9b05-e4f757b9185b',
        title: i18n.global.t('toast-title-device-temperature-low', { device_name: "DX010" }),
        // title: "設備編號(DX010)水溫過低...",
        // content: i18n.global.t('toast-content-device-temperature-low', { room_no: 7, bed_no: 31, device_name: "DX010", target_temp: 33.0, current_temp: 27.2 }),
        params: { room_no: 7, bed_no: 31, device_name: "DX010", target_temp: 33.0, current_temp: 27.2 },
        translate_name: { title: "toast-title-device-temperature-low", content: "toast-content-device-temperature-low" },
        // content: `位於「<font color="red"><strong>7號房-31號床</strong></font>」
        //     設備編號「<font color="red"><strong>DX010</strong></font>」
        //     設定水溫<font color="red">33</font>度目前水溫<font color="red">27.2</font>度，
        //   若持留意水溫過低請連絡相關人員進行了解。`,
        cfg: { closeAfter: -1, timestamp: new Date() - 7200000, route: 'Home' },
      },
    ];

    const alertList = [
      {
        id: uuid(),
        level: "critical",
        title: "污桶位移",
        message:
          "位於「1號房-3號床」設備編號「DX002」發生「污桶位移」告警 請立即進行處理",
        params: { room_no: 1, bed_no: 3, device_name: "DX002" },
        translate_name: { title: "alert-title-device-waste-bucket-misplaced", content: "alert-content-device-waste-bucket-misplaced" },
        route: 'Home',
      },
      {
        id: uuid(),
        level: "critical",
        title: "排遺數據異常",
        message:
          "位於「5號房-19號床」設備編號「DX011」發生「生理數據異常」請連絡相關人員進行處理",
        params: { room_no: 5, bed_no: 19, device_name: "DX011" },
        translate_name: { title: "alert-title-device-statistic-abnormal", content: "alert-content-device-statistic-abnormal" },
        route: 'Home',
      },
      // {
      //   level: "critical",
      //   title: "登入失敗",
      //   message:
      //     "帳號或密碼不正確，請重新輸入。",
      //   route: 'Login',
      // },
    ];
    return { toastList, alertList }

  },
  mutations: { // $store.commit('increment',1) // 之後要把新增/修改項目至 state 放這裡 ... 
    addAlert: ((state, { id = uuid(), level = 'critical', title, message, route = 'global' }) => {
      // 需要的欄位有 level title message route
      // console.log({ id, level, title, message, route })
      state.alertList.push({ id, level, title, message, route });
    }),
    // id: uuid(),
    // title: "設備編號(DX010)水溫過低...",
    // content: `位於「<font color="red"><strong>7號房-31號床</strong></font>」
    //     設備編號「<font color="red"><strong>DX010</strong></font>」
    //     設定水溫<font color="red">33</font>度目前水溫<font color="red">27.2</font>度，
    //   若持留意水溫過低請連絡相關人員進行了解。`,
    // cfg: { closeAfter: -1, timestamp: new Date() - 7200000, route: 'Home' },
    // addToast: ((state, { id = uuid(), title, content, { closeAfter = -1, timestamp = new Date(), route } }) => {
    addToast: ((state, { id = uuid(), title, content, cfg }) => {
      const { closeAfter = -1, timestamp = new Date(), route = 'global' } = cfg;

      const param = { id, title, content, cfg: { closeAfter, timestamp, route } }
      // console.log('addToast:---->', param);
      // 需要的欄位有 level title message route

      // console.log({ id, state, level, title, message, route })
      state.toastList.push(param);
    }),
    deleteAlartById: ((state, id) => {
      state.alertList = state.alertList.filter(alert => alert.id !== id)
    }),
    deleteToastById: ((state, id) => {
      let tmp = state.toastList.filter(toast => {
        // console.log('deleteToastById:::', toast.id, id, toast.id !== id);
        return toast.id !== id
      })
      // console.log('tmp:', tmp);
      // console.log('before::::::::', state.toastList)
      state.toastList = tmp;
      // console.log('after::::::::', state.toastList)
    })
  },
  actions: { // asynchronized request $store.dispatch('addRandomNumber')

  },
  getters: {
    getAlertList: (states) => (route_name) => {
      console.log('states:', states.alertList);
      console.log('route_name:', route_name);

      let temp = states.alertList.filter(item => item.route === route_name).map(item => {
        if (item.translate_name.title) {
          item.title = i18n.global.t(item.translate_name.title, item.params || {});
        }
        if (item.translate_name.content) {
          item.message = i18n.global.t(item.translate_name.content, item.params || {});
        }
        return item;
      });

      console.log('temp:', temp);

      return temp;
    },
    getToastList: (states) => {
      return states.toastList.map(item => {
        if (item.translate_name?.title) {
          item.title = i18n.global.t(item.translate_name.title, item.params || {});
        }
        if (item.translate_name?.content) {
          item.content = i18n.global.t(item.translate_name.content, item.params || {});
        }
        return item;
      });
    }

  }
}