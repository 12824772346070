<template>
  <div>
    <div class="space-up error" v-if="error">
      <div class="container">
        <div class="dflex justify-content-center text-center mt-4">
          <div class="header">
            <h2>資訊看版載入時發生錯誤!!!!</h2>
            <br />
          </div>
          <div>
            <h3>
              <p style="color: red">{{ error }}</p>
            </h3>
            <br />
          </div>
          <div class="mt-2">
            <h2>請連絡程式開發人員</h2>
          </div>
        </div>
      </div>
    </div>
    <Suspense v-else>
      <template #default>
        <Main />
      </template>
      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </div>
</template>

<script>
import { onErrorCaptured, ref } from "vue";

import Main from "@/components/Main.vue";
import Loading from "@/components/Loading.vue";

// import { useI18n } from "vue-i18n";

// import store from "@/data";
// console.log("------------state", store);
export default {
  name: "APP",
  components: {
    Main,
    Loading,
  },

  setup() {
    // provide("store", store);
    const error = ref(null);
    onErrorCaptured((e) => (error.value = e));

    // const { t, locale } = useI18n({ useScope: 'global' });

    return { error, 
    // t, locale 
    };
  },
};
</script>


<style lang="scss">
@import "~bootstrap/scss/bootstrap";
</style>

<style scoped>
.header {
  text-size-adjust: 130%;
}
</style>
