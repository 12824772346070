// import dateFormat from "dateformat";

// let getrandomtime = function () {
//   return dateFormat(
//     +new Date() - Math.floor(Math.random() * 360000) + 30000,
//     "yyyy-mm-dd HH:MM:ss"
//   );
// };
import { v4 as uuid } from 'uuid';

export default {
  namespaced: true,
  state() {
    let userList = { admin: "admin", guest: "guest" }

    let permissionList = {
      admin: {
        "/location": ["read", "write", "execute"] // 先隨便寫一寫 ...
      }
    }

    let isLogin = false;
    let accessToken = '';
    let username = '';
    let errorMsg = '';
    let sessionTimeout = 0;

    return { isLogin, username, userList, permissionList, accessToken, errorMsg, sessionTimeout }

  },
  mutations: { // $store.commit('increment',1) // 之後要把新增/修改項目至 state 放這裡 ... 
    doLogin: ((state, { username, password, keepalive = false }) => {
      console.log(username.value, password.value, state.userList[username.value]);
      if (state.userList[username.value] === password.value) {
        console.log('login success', keepalive);
        state.isLogin = true;
        state.username = username;
        state.accessToken = uuid();
        if (keepalive) {
          state.sessionTimeout = 0;
        } else {
          state.sessionTimeout = new Date + (3600 * 12);
        }
      }
    })
  },
  actions: { // asynchronized request $store.dispatch('addRandomNumber')
    doLogin: ((ctx, authData) => {
      ctx.commit('doLogin', authData);
      let result = ctx.getters['checkLogin'];
      console.log('action result:', result)
      return result;
    }),
    doLogout: ((ctx) => {
      ctx.state.isLogin = false;
      ctx.state.username = '';
      ctx.state.sessionTimeout = 0;
    })
  },
  getters: {
    checkLogin: (states) => {
      if (states.sessionTimeout > 0 && (states.sessionTimeout < +new Date)) {
        states.isLogin = false;
        states.sessionTimeout = 0;
        states.username = '';
      }
      let username = '';
      let permission = {};
      if (states.isLogin === true) {
        username = states.username;
        if (states.permissionList[username] !== undefined) {
          permission = states.permissionList[username]
        }

      }

      return { username, permission };

    }

  }
}