<template>
  <div class="fixed-bottom">
    <div class="position-relative">
      <div class="toast-container position-absolute bottom-0 end-0 p-3">
        <div class="d-flex flex-column-reverse sp-1">
          <!-- toast drop area-->
          <transition-group name="toast">
            <bs-toast
              v-for="item in toastList"
              :key="item.id"
              :title="item.title"
              :content="item.content"
              :cfg="item.cfg"
              :id="item.id"
              @close="closeToast"
            />
          </transition-group>
          <!-- toast drop area-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import BsToast from "@/components/bootstrap/BsToast.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "ToastArea",
  components: { BsToast },
  setup() {
    const store = useStore();
    const route = useRoute();
    let toastList = computed(() => {
      let list = store.getters["notification/getToastList"].filter((toast) => {
        return toast.cfg?.route === route.name || toast.cfg?.route === "global";
      });

      console.log("toastList changed @ ToastArea:::::", list);
      return list;
    });
    // toast 從子組件內部關，vuex由父組件去刪除 ... 
    const closeToast = (id) => {
      console.log("delete Toast id:", id);
      store.commit("notification/deleteToastById", id);
      console.log("toastList:-----------------", toastList);
    };
    return { toastList, closeToast };
  },
});
</script>

<style>
/* .toast-move, 
.toast-enter-active,
.toast-leave-active {
  transition: all 5s ease;
}

.toast-enter-from,
.toast-leave-to {
  opacity: 0;
  transform: translateY(30px);
}


.toast-leave-active {
  position: absolute;
} */

/* .fade {
      transition: opacity 0.5s linear !important;
} */
</style>