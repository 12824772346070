<template>
  <div class="fixed-bottom">
    <div class="position-relative">
      <div class="position-absolute bottom-0 start-0 p-3">
        <div class="d-flex flex-column-reverse sp-1">
          <div v-for="(btn, idx) in btnList" v-show="btn.show" :key="idx">
            <button
              type="button"
              class="float-btn mt-3"
              :data-bs-toggle="btn.toggle"
              :data-bs-target="btn.target"
            >
              <font-awesome-icon class="fa-2xl" :icon="btn.icon" />
            </button>
          </div>
          <span class="fab-container">
            <div
              class="main-button"
              @click="toggleLangs()"
              :style="{
                transform: showLang ? 'scale(1.2)' : '',
              }"
            >
              <font-awesome-icon class="fa-xl" icon="language" />
            </div>
            <div
              v-for="(lang, idx) in languages"
              :key="idx"
              :style="calTranX(idx + 1, lang)"
              class="sub-button text-nowrap"
              @click="setLocale(lang)"
            >
              {{ t(lang) }}
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { defineComponent, watchEffect, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";

// modal 元件
// import PatientEdit from "@/components/modal/PatientEdit.vue";

export default defineComponent({
  name: "FloatBtn",
  // components: { PatientEdit },
  setup() {
    const route = useRoute();
    const btnList = reactive([
      {
        toggle: "offcanvas",
        target: "#sidebarCanvas",
        icon: "bars",
        show: true,
      },
      {
        toggle: "modal",
        target: "#patientEditModal",
        icon: "user-plus",
        show: true,
        route: "Patient",
      },
      {
        toggle: "modal",
        target: "#genericModal",
        icon: "plus",
        show: true,
        route: "Emergency",
      },
      {
        toggle: "modal",
        target: "#buildingEditModal",
        icon: "plus",
        show: true,
        route: "Location",
      },
      {
        toggle: "modal",
        target: "#devicePropertyModal",
        icon: "pen-ruler",
        show: true,
        route: "Device",
      },
      {
        toggle: "modal",
        target: "#deviceEditModal",
        icon: "plug-circle-plus",
        show: true,
        route: "Device",
      },
      {
        toggle: "modal",
        target: "#practitionerEditModal",
        icon: "user-plus",
        show: true,
        route: "Practitioner",
      },
    ]);

    const { t, locale } = useI18n();

    watchEffect(() => {
      btnList[0].show = route.name === "Home";
      for (let btn of btnList) {
        if (btn.route === undefined || btn.route === route.name) {
          btn.show = true;
        } else {
          btn.show = false;
        }
      }
    });

    const languages = ["zh-TW", "en-US", "ja-JP"];

    const showLang = ref(false);

    const toggleLangs = () => {
      showLang.value = !showLang.value;
      if (showLang.value === true) {
        document.addEventListener("click", closeMenu);
      } else {
        document.removeEventListener("click", closeMenu);
      }
    };

    const calTranX = (order, code) => {
      let shift = showLang.value ? 60 * order : 0;
      let result = {
        transform: `translateX(${shift}px)`,
        "background-color": locale.value === code ? "#0ABAB5" : "#FFF",
        color: locale.value === code ? "#FFF" : "#000",
      };
      return result;
    };

    const setLocale = (code) => {
      locale.value = code;
      localStorage.setItem("locale", code);
      // console.log('setLocale:', localStorage.getItem("locale"));
    };

    const closeMenu = (e) => {
      console.log("closeMenu e:", e.target.closest("div"));
      console.log("showLang.value:", showLang.value);
      if (
        !e.target.closest("div") || (e.target.closest("div")?.classList.contains(["main-button"]) === false &&
        e.target.closest("div")?.classList.contains(["sub-button"]) === false )
      ) {
        if (showLang.value) {
          toggleLangs();
        }
      }
    };

    return {
      btnList,
      toggleLangs,
      languages,
      calTranX,
      setLocale,
      t,
      showLang,
    };
  },
});
</script>

<style>
.float-btn {
  /* border: none; */
  width: 50px;
  height: 50px;
  padding: 7px 10px;
  border-radius: 25px;
  font-size: 10px;
  text-align: center;
  /* background: none; */
  opacity: 0.9;
  /* z-index: 65535; */
}

.fab-container {
  display: flex;
  width: 50px;
  height: 50px;
  position: relative;
}

.main-button,
.sub-button {
  width: 50px;
  height: 50px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #000;
  border-radius: 25px;
  background: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}
.main-button {
  z-index: 10;
}

</style>
