import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "test" */ '@/views/Test.vue')
  },
  {
    path: '/device',
    name: 'Device',
    component: () => import(/* webpackChunkName: "device" */ '@/views/Device.vue')
  },
  {
    path: '/practitioner',
    name: 'Practitioner',
    component: () => import(/* webpackChunkName: "attendant" */ '@/views/Practitioner.vue')
  },
  {
    path: '/patient',
    name: 'Patient',
    component: () => import(/* webpackChunkName: "patient" */ '@/views/Patient.vue')
  },
  {
    path: '/emergency',
    name: 'Emergency',
    component: () => import(/* webpackChunkName: "patient" */ '@/views/Emergency.vue')
  },
  {
    path: '/test/:id',
    name: 'TestParameters',
    component: () => import(/* webpackChunkName: "test" */ '@/views/Test.vue'),
    beforeEnter(to) {
      console.log('beforeEnter:', to, to.path.substring(1), to.path.substring(1).split("/"));
      const id = parseInt(to.params.id);
      if (id !== 1234) {
        return {
          name: "not-found",
          params: { pathMatch: to.path.substring(1).split('/') }, // pathMatch: 陣列
          query: to.query,
          hash: to.hash,
        }
      }
    }
  },
  {
    path: '/location',
    name: 'Location',
    component: () => import(/* webpackChunkName: "location" */ '@/views/Location.vue'),
    meta: {
      requiresAuth: false
    }

  },
  {
    path: '/drag',
    name: 'Drag',
    component: () => import(/* webpackChunkName: "Drag" */ '@/views/Drag.vue')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


// let loggined = true


router.beforeEach((to, from, next) => {
  let loggined = store.getters['auth/checkLogin'].username !== '';
  // console.log('loggined<><><>:', loggined, store.getters['auth/checkLogin']);

  // console.log('store:', store)
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (loggined === true) {
      next();
    } else {
      alert("you dont have access!");
      next("/Login");
    }
  } else {
    next();
  }
});

export default router
