<template>
  <div class="row">
    <div
      v-for="(room, index) in filterdRoom"
      :key="index"
      class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3 mt-3"
    >
      <room-detail
        :no="room.id"
        :name="room.name"
        :deviceCount="room.deviceCount"
        :hasAlert="room.hasAlert"
        :hasWarning="room.hasWarning"
        :lastUpdateTime="room.lastUpdateTime"
        @onRoomChanged="selectRoom"
      />
    </div>

    <div
      v-for="(user, index) in filterdUser"
      :key="index"
      class="col-12 col-md-6 col-lg-4 col-xl-3 mt-3"
    >
      <!-- 設備資訊 -->
      <user-detail :user="user" />
    </div>

    <div class="col-12 col-sm-6 col-lg-4 col-xl-3 mt-3"></div>

    <div class="col-12 col-sm-6 col-lg-4 col-xl-3 mt-3"></div>
  </div>

  <div>
    <notify-setting />
    <!-- <patient-edit /> -->
   </div>
  
  <!-- 醫囑 -->
  <div class="d-flex justify-content-center mt-4">
    <div class="container">
      <div class="row">
        <div class="col-5">
          <compliance-modal
            v-for="item in medicalCareModalList"
            :key="item.id"
            :data="item"
          />
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
// console.log("==========");
// import BsAlert from "@/components/bootstrap/BsAlert.vue";
import BsCard from "@/components/bootstrap/BsCard.vue";
// import BsToast from "@/components/bootstrap/BsToast.vue";
import BsModal from "@/components/bootstrap/BsModal.vue";

import RoomDetail from "@/components/RoomDetail.vue";
import UserDetail from "@/components/UserDetail.vue";

// import { Toast } from "bootstrap";
import { Tooltip } from "bootstrap";

// modal 彈窗元件
import ComplianceModal from "@/components/modal/ComplianceModal.vue"; // 註記系統
import NotifySetting from "@/components/modal/NotifySetting.vue"; // 告警設定
// import PatientEdit from "../components/modal/PatientEdit.vue";

// import TestSwipe from "@/components/TestSwipe.vue";

import { useStore } from "vuex";
import { onMounted, computed, onBeforeUnmount, defineComponent } from "vue";

// const { useActions: ubusActions } = createNamespacedHelpers("ubus") // eslint-disable-line

export default defineComponent({
  /* eslint-disable */
  components: {
    // BsPopover,
    // bootstrap 元件
    // BsAlert,
    BsCard,
    // BsToast,
    BsModal,
    RoomDetail,
    UserDetail,
    // TestSwipe,
    // modals 彈窗
    ComplianceModal,
    NotifySetting,
    // PatientEdit,
    // SideBar,
  },
  /* eslint-enable */
  emits: ["updateListEmit", "onRoomChanged"],

  async setup() {
    const store = useStore();
    console.log("store:", store); // store.state.<模組名稱>.屬性
    // let device = computed(() => store.getters["fake/filterdRoom"]);
    // let roomNumbers = computed(() => store.getters["fake/roomNumbers"]);
    let filterdUser = computed(() => store.getters["fake/filterdUser"]); // 當roomNumber有值時，這邊會有值就會列在頁面上

    let medicalCareModalList = computed(
      () => store.state.fake.medicalCareModalList
    ); // 這樣寫才會是 ref
    // let toastLists = computed(() => store.state.device.toastLists); // 這樣寫才會是 ref
    let modalList = computed(() => store.state.fake.modalList); // 這樣寫才會是 ref
    // let alertList = computed(() => store.state.device.alertList); // 這樣寫才會是 ref

    // console.log("alertList:", alertList);
    console.log("modalList:", modalList);
    // console.log("toastLists:", toastLists);

    // console.log("roomNumbers:", roomNumbers);

    // emit -> onModalIdCreated
    const updateModalId = (modalId) => {
      console.log("updateModalId:", modalId);
    };

    let targetRoom = computed(() => store.state.fake.targetRoom); // 這樣寫才會是 ref
    let filterdRoom = computed(() => store.getters["fake/filterdRoom"]); //
    // emit -> onRoomChanged
    const selectRoom = (roomNo) => {
      // console.log("selectRoom11", roomNo);
      if (targetRoom.value !== roomNo) {
        store.commit("fake/setTargetRoom", roomNo);
      } else {
        store.commit("fake/setTargetRoom", 0);
        // setTargetRoom(0);
      }
    };

    onMounted(() => {
      console.log("Home.vue mounted");
      // 失效了，待修
      Array.from(
        document.querySelectorAll('span[data-bs-toggle="tooltip"]')
      ).forEach((tooltipNode) => new Tooltip(tooltipNode));
    });

    onBeforeUnmount(() => {
      console.log("Home.vue onBeforeUnmount");
    });

    return {
      medicalCareModalList,
      // toastLists,
      modalList,
      // alertList,
      // addToast,
      updateModalId,
      filterdRoom,
      filterdUser,
      selectRoom,
      // getDeviceInfo,
      // ubusLogin
      // device,
    };
  },
});
</script>

<style>
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.info-group > .badge,
.info-group > .btn,
.info-group > .btn-group {
  margin: 0.25rem 0.125rem;
}
body {
  overflow-x: hidden; /* Hide scrollbars */
}
/* .scrollable {
  max-height: 75px;
  overflow-y: auto;
  overflow-x: hidden;
} */
</style>

<style scoped>
.fs-xs {
  font-size: 0.6rem !important;
}

.btn {
  padding: 1px;
}

.popover-header {
  background: #ffff99;
}

/* .alert-block {
  position: sticky;
  top: 43px;
  z-index: 1000;
  background-color: #ffffff;
} */

.modal {
  z-index: 10040;
}
</style>
