<template>
  <div class="card h-100">
    <div class="card-header text-center fs-5 "> {{ $t('bed-number-describer', {bed_no: this.$props.user.bedNo}) }} </div>
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <!-- <div>病歷號碼：11011327001</div> -->
        <div></div>
        <div class="info-group">
          <!-- <bs-popover
            title="指示"
            content="<div>原因說明文字<br/>開始時間：2021/10/29 23:00<br />結束時間：2021/10/30 11:00<br /><ul><li>禁水</li><li>禁食</li></ul></div>"
            trigger="hover"
            placement="bottom"
            html="true"
            level="info"
          > -->
            <span v-for="(status, index) in this.$props.user.status" :key="index" class="badge bg-danger">{{status}}</span>
          <!-- </bs-popover> -->
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-4">{{$t('fullname')}}</div>
        <div class="col-4 text-center">{{this.$props.user.name}}</div>
        <div class="col-4 text-end">
          {{ this.$props.user.gender }}
          <font-awesome-icon :icon="(this.$props.user.gender === '男') ? 'mars' : 'venus'" />
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-4">生日</div>
        <div class="col-4 text-center">1940/9/18</div>
        <div class="col-4 text-end">81歲</div>
      </div> 
      
      -->
      <hr />

      <log-list :device-info="this.$props.user.deviceInfo" :device-no="this.$props.user.deviceNo" :list="this.$props.user.log" />

      <alert-list :list="this.$props.user.alerts" level="alert" />
      <alert-list :list="this.$props.user.warnings" level="warning" />
    </div>
    <div class="card-footer fs-xs text-end">Since：{{ this.$props.user.lastUpdateTime }}</div>
  </div>
</template>

<script>
// import BsPopover from "@/components/bootstrap/BsPopover.vue";
import LogList from "@/components/device/LogList.vue";
import AlertList from "@/components/device/AlertList.vue";

export default {
  name: "DeviceDetail",
  components: {
    // BsPopover,
    LogList, // eslint-disable-line
    AlertList,
  },
  props: {
    user: {
      type: Object
    }
  },
  setup() {
    // console.log('--------------------props', props, props.user.deviceInfo)
    return {  }
  },
};
</script>
    
<style>
</style>

