
function ParamBuilder(params) { // eslint-disable-line
    console.log('params-------------------->:', params)
    const token = params.token || '00000000000000000000000000000000'
    const namespace = params.namespace
    const procedure = params.procedure
    const method = params.method || 'call'
    const data = params.data || {}
    let template = { jsonrpc: '2.0', id: 1, method, params: [token, namespace, procedure, data] }
    const resultString = JSON.stringify(template)
    console.log('ParamBuilder:', resultString)
    return resultString
}

export async function UbusRequest(url, argument) {
    let dataToSend = await ParamBuilder(argument)
    console.log('dataToSend', dataToSend)
    const postData = {
        // credentials: "same-origin",
        // mode: "same-origin",
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: dataToSend
    }
    const response = await fetch(url, postData)
    console.log(response.ok, response.status)
    const data = await response.json()
    return data
}

// export { UbusRequest }